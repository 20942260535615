/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import Header from "Components/common/Header";
import { withRouter } from "react-router-dom";
import CustomerEmail from "./CustomerEmail";
import DateFilter from "./DateFilter";
import CustomerProfile from "./CustomerProfile";
import TrialUserList from "./TrialUserList";
import DeleteSEO from "./DeleteSEO";

const ManageCustomers = (props) => {
  const [backBtn, setBackBtn] = useState(false);
  const [showCustomerDetails, setCustomerDetails] = useState(false);
  const [showDateFilter, setShowDateFilter] = useState(false);
  const [showdeletecontact, setdeletecontact] = useState(false);
  const [showdeletebutton, setdeletebutton] = useState(true);

  const backButtonNavigtion = (e) => {
    setCustomerDetails(false);
    setShowDateFilter(false);
    setBackBtn(false);
    setdeletecontact(false);
    setdeletebutton(true);
  };

  const showCustomerProfile = (value) => {
    setCustomerDetails(value);
    setdeletebutton(false);
    setBackBtn(true);
    setdeletecontact(false);
  };

  const showDateFilterList = (value) => {
    setShowDateFilter(value);
    setdeletecontact(false);
    setBackBtn(true);
    setdeletebutton(false);
  };

  const removeCTOContancts = () => {
    setdeletecontact(true);
    setdeletebutton(false);
    setBackBtn(true);
  };

  return (
    <div className="manageCustomerPage">
      <div className="layout">
        <Header
          title={"Manage Customers"}
          cbk={backButtonNavigtion}
          showBackBtn={backBtn}
        />
        <div className="body">
          {!showCustomerDetails && !showDateFilter && !showdeletecontact && (
            <CustomerEmail showCustomerProfile={showCustomerProfile} />
          )}
          {showCustomerDetails && !showDateFilter && !showdeletecontact && (
            <CustomerProfile />
          )}
          {!showDateFilter && !showCustomerDetails && !showdeletecontact && (
            <DateFilter
              className="dateFilterSection table"
              showDateFilter={showDateFilterList}
              title="Active trial users"
            />
          )}
          {showDateFilter && !showCustomerDetails && !showdeletecontact && (
            <TrialUserList />
          )}
          {showdeletecontact && <DeleteSEO />}
          {showdeletebutton && (
            <div>
              <button onClick={removeCTOContancts} className="secondaryButton">
                Remove SEO contacts
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default withRouter(ManageCustomers);
export { ManageCustomers };

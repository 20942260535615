/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import Button from "Components/common/Button";
import { customerDetails } from "Stores/customerDetails";
import InputTextBox from "Components/common/InputTextBox";

const CustomerEmail = ({ showCustomerProfile = false }) => {
  const [email, setEmail] = useState("");
  const [emailErrorMsg, setEmailErrorMsg] = useState("");
  const [loader, setLoader] = useState(false);

  const handleChange = (id = "", value = "") => {
    setEmail(value?.trim());
    emailErrorMsg && setEmailErrorMsg(false);
  };

  const viewAccountDetailsAction = () => {
    const redirectionCbk = () => {
      showCustomerProfile(true);
      setLoader(false);
    };
    const errorCbk = () => {
      setEmailErrorMsg("User email address is not found");
      setLoader(false);
    };
    if (!loader) {
      if (email) {
        customerDetails.setCustomerEmail(email);
        setLoader(true);
        customerDetails.getCustomerDetails(email, redirectionCbk, errorCbk);
        customerDetails.getWipeAllDataStatus(email);
        emailErrorMsg && setEmailErrorMsg("");
      } else {
        !emailErrorMsg && setEmailErrorMsg("Please enter a valid user email");
      }
    }
  };

  const inputObj = {
    name: "blockMemberInput",
    id: "blockMemberInput",
    placeHolder: "Enter a email address",
    type: "email",
    value: email,
    err: emailErrorMsg,
    change: handleChange,
  };

  const buttonObj = {
    id: "viewAccountDetails",
    name: "viewAccountDetails",
    type: "button",
    className: `primaryButton ${loader ? "loading" : ""}`,
    btnText: "View Account Details",
    loader,
    click: viewAccountDetailsAction,
  };

  return (
    <div className="customerEmailCont">
      <div className="contentBox">
        <h3>View Customer/User account details</h3>
        <div className="emailInputCont">
          <InputTextBox {...inputObj} />
        </div>
        <div className="buttonCont">
          <Button {...buttonObj} />
        </div>
      </div>
    </div>
  );
};

export default withRouter(CustomerEmail);
export { CustomerEmail };

import React, { useState } from "react";

export const ToogleButton = (props) => {
  const {
    label = "",
    id = "",
    checked = false,
    allowToggle = true,
    loader = false,
    callback = () => {},
  } = props || {};

  // const [value, setValue] = useState(checked);

  const handleChange = (event) => {
    if (allowToggle) {
      let val = event.currentTarget.checked;
      // setValue(val);
      callback(id, val);
    }
  };

  return !loader ? (
    <div className="toggleSection">
      <div className="toggleCheckbox" id={id}>
        <input
          type="checkbox"
          className="inputCheckbox"
          checked={checked || ""}
          onChange={(event) => handleChange(event)}
          value={checked || ""}
        />
        <div className="knobs" />
        <div className="layer" />
      </div>
      {label && (
        <span className={`labelText ${checked ? "active" : ""}`}>{label}</span>
      )}
    </div>
  ) : (
    <div className="toggleSectionLoader linear-background" />
  );
};

export default ToogleButton;

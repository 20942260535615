import { toasterState } from "../Components/common/Toaster";
import ConstantObj from "Static/constant";

let emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

/* eslint-disable no-undef */
const Utils = {};
// eslint-disable-next-line no-useless-escape

Utils.showApiFailureResponse = (response) => {
  if (response && response.response && response.response.status) {
    const status = response.response.status;
    if (status === 403) {
      toasterState.setToastMsg("Access Denied", "failure");
    } else if (status === 401) {
      toasterState.setToastMsg("Please login to continue", "failure");
    } else {
      toasterState.setToastMsg(
        "Something went wrong. Please try again later",
        "failure"
      );
    }
  }
};

Utils.formatDate = (date) => {
  let d = new Date(date);
  return `${
    ConstantObj.months[d.getMonth()]
  } ${d.getDate()}, ${d.getFullYear()}`;
};

Utils.removeReferenceFromObject = (object) => {
  if (object) {
    return JSON.parse(JSON.stringify(object));
  }
};

Utils.getDateDiff = (date) => {
  if (date) {
    let formatDate = new Date(date);
    let dateValue = new Date(
      `${
        formatDate.getMonth() + 1
      }/${formatDate.getDate()}/${formatDate.getFullYear()}`
    );
    let dateDiff = dateValue.getTime() - new Date().getTime();
    return dateDiff / (1000 * 3600 * 24);
  }
  return false;
};

Utils.validateEmailInput = (email = "") => {
  return (email && emailRegex.test(email)) || false;
};

Utils.hasObject = (obj = {}) => {
  return obj && Object.keys(obj)?.length > 0;
};

Utils.preventDefaultFn = (event) => {
  if (event) {
    event.persist();
    event.preventDefault();
    event.stopPropagation();
  }
};

Utils.isSubmitKeyAction = (event = {}) => {
  Utils.preventDefaultFn(event);
  if (
    Utils.hasObject(event) &&
    (event?.key === "Enter" || event?.charCode === 13 || event?.keyCode === 13)
  ) {
    return true;
  }
  return false;
};

export default Utils;
export { Utils };

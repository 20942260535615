/* eslint-disable array-callback-return */
/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import InfoTag from "Components/common/InfoTag";
import makeApi, { URLS } from "Utils/apiURL";
import Utils from "Utils/utils";
import { toasterState } from "Components/common/Toaster";
import { confirmationPopupState } from "Components/common/ConfirmationPopup";
import { customerDetails } from "Stores/customerDetails";

const CustomerSubscription = props => {
  const { data = [] } = props;
  const [subscriptionData, setSubscriptionData] = useState(data);

  const isPlanSuspended = status => {
    return status && status.toLowerCase() === "suspended";
  };

  const activateSubscriptionBtnAction = async teamSubscriptionId => {
    const activateSubscriptionCbk = async buttonLoadingFn => {
      try {
        const config = {
          url: URLS.activateSubscription,
          method: "POST",
          data: { teamSubscriptionId }
        };
        toggleLoaderFlag(teamSubscriptionId, true);
        let response = await makeApi(config);
        if (response && response.data) {
          const respData = response.data;
          if (respData.success) {
            buttonLoadingFn(false);
            confirmationPopupState.setShowPopup(false);
            customerDetails.getCustomerDetails(customerDetails.customerEmail);
            toasterState.setToastMsg(
              "Subscription activated successfully",
              "success"
            );
          } else {
            toggleLoaderFlag(teamSubscriptionId, false);
            toasterState.setToastMsg("Something went wrong", "failure");
          }
        } else {
          Utils.showApiFailureResponse(response);
          toggleLoaderFlag(teamSubscriptionId, false);
        }
      } catch (error) {
        console.error("Activate Subscription error ", error);
      }
    };

    confirmationPopupState.setPopupValues({
      content: "Are you sure you want to activate the subscription?",
      actionBtnText: "Yes",
      isCancelBtnNeeded: true,
      callback: activateSubscriptionCbk,
      loadingMsg: "Activating subscription. Please wait..."
    });
    confirmationPopupState.setShowPopup(true);
  };

  const toggleLoaderFlag = (subid, flag) => {
    if (subid && subscriptionData) {
      subscriptionData.map(value => {
        if (value && value.id && value.id === subid) {
          value.planStatusLoader = flag;
        } else if (subid === null) {
          value.planStatusLoader = flag;
        }
      });
      setSubscriptionData(Utils.removeReferenceFromObject(subscriptionData));
    }
  };

  useEffect(() => {
    if (data) {
      setSubscriptionData(data);
      toggleLoaderFlag(null, false);
    }
  }, [data]);

  return subscriptionData && subscriptionData.length ? (
    <div className="tableInfo">
      <div className="innerCont table">
        <div className="titleCont">
          <div className="title">Subscription Details</div>
        </div>
        <div className="bodyCont">
          {subscriptionData.map((value, key) => {
            const {
              annual,
              id,
              status,
              renewalDate,
              startDate,
              planName,
              subscriptionPlanId,
              planStatusLoader
            } = value;
            return (
              <div key={key} className="subscriptionParentCont">
                <InfoTag
                  classValue="planId"
                  label="Plan"
                  value={subscriptionPlanId}
                />
                <InfoTag
                  classValue="purchaseDate"
                  label="Purchase Date"
                  value={startDate && Utils.formatDate(startDate)}
                />
                <InfoTag
                  classValue="renewalDate"
                  label="Renewal Date"
                  value={renewalDate && Utils.formatDate(renewalDate)}
                />
                <InfoTag
                  classValue="planName"
                  label="Plan Name"
                  value={planName}
                />
                <InfoTag
                  classValue="renewalCycle"
                  label="Renewal Cycle"
                  value={annual ? "Annually" : "Monthly"}
                />
                <div className="status">
                  <InfoTag
                    classValue="planStatus"
                    label="Plan Status"
                    value={status && status.toLowerCase()}
                    loader={planStatusLoader}
                    showSideButton={isPlanSuspended(status)}
                    cbk={e => activateSubscriptionBtnAction(id)}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  ) : (
    ""
  );
};

export default CustomerSubscription;
export { CustomerSubscription };

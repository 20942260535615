import { makeApi, URLS } from "Utils/apiURL";
import userDetail from "Stores/userDetail";

export const loginValidate = async () => {
  try {
    const config = {
      url: URLS.loginValidation,
      method: "GET"
    };
    const userObj = {};
    const response = await makeApi(config);
    if (response && response.data && response.data !== "") {
      const { isLoggedIn, email, hasteam, memberId, role } =
        response && response.data;
      userObj.email = email;
      userObj.userLoggedIn = isLoggedIn;
      userObj.hasTeam = hasteam;
      userObj.memberId = memberId;
      userObj.role = role;
      userDetail.setUserInfo(userObj);
      return true;
    } else {
      userObj.userLoggedIn = false;
      userDetail.setUserInfo(userObj);
      return false;
    }
  } catch (error) {
    console.error("loginValidate error ", error);
  }
};

import { observable, action } from "mobx";

export const userObj = {
  name: null,
  email: null,
  timeZone: null,
  userLoggedIn: "",
  team: null,
  hasTeam: true
};

export const feaureFlag = {
  noOfSeatsEligible: null,
  noOfSeatsUsed: null,
  noOfSequencesEligible: null,
  noOfSequencesUsed: null,
  noOfContactsEligible: null,
  noOfContactsUsed: null,
  teamReportEnabled: null,
  crmAutoBccEnabled: null,
  noOfTemplatesEligible: null,
  noOfTemplatesUsed: null,
  email: null,
  memberIds: null,
  subscriptionPlanId: null
};

export const inviteTeam = {
  name: null,
  email: null,
  adminName: null,
  adminEmail: null,
  token: null,
  newUser: null
};

export const upgradePopupContent = {
  page: null,
  show: null,
  overlay: null
};

export const featureFlag = {};

class UserDetail {
  @observable userInfo = { ...userObj };
  @observable userTeamInfo = { ...inviteTeam };
  @observable connectedAccounts = {};
  @observable userFeatureFlag = { ...feaureFlag };
  @observable upgradePopup = { ...upgradePopupContent };
  @observable showUpgradePopup = false;

  @action
  setUserInfo(obj) {
    this.userInfo = { ...userObj, ...obj };
  }

  @action
  setUserTeamInfo(obj) {
    this.userTeamInfo = { ...inviteTeam, ...obj };
  }

  @action
  setConectedAccounts(obj) {
    this.connectedAccounts = { obj };
  }

  @action
  setUserFeatureFlag(obj) {
    this.userFeatureFlag = { ...feaureFlag, ...obj };
  }

  @action
  setUpgradePopupContent(obj) {
    this.showUpgradePopup = obj.show;
    this.upgradePopup = { ...upgradePopupContent, ...obj };
  }
}

const userDetail = new UserDetail();
export { userDetail };
export default userDetail;

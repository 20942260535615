/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */

import React from "react";
import { Link, withRouter, NavLink } from "react-router-dom";
import { ReactComponent as SalesgearLogo } from "Assets/svg/logo.svg";

const SideNav = () => {
  const sideNavItems = [
    {
      path: "/admin/manageCustomers",
      name: "managecustomers",
      title: "Manage Customers",
      class: "headerIcons",
      isMaterialIcon: true,
      iconPath: "people_outline",
    },
  ];

  return (
    <div className="sideBar">
      <Link to="/">
        <SalesgearLogo />
      </Link>
      <div className="menuList">
        {sideNavItems.map((item, index) => {
          return (
            <NavLink
              key={index}
              to={item.path}
              key={item.name}
              activeClassName="selected-path"
              className={`${item.name}`}
            >
              {item.isMaterialIcon && (
                <i className="material-icons" id={item.name}>
                  {item.iconPath}
                </i>
              )}
            </NavLink>
          );
        })}
      </div>
    </div>
  );
};

export default withRouter(SideNav);

import { set } from "mobx";
import React, { useState } from "react";
import { makeApi, URLS } from "Utils/apiURL";
import Button from "Components/common/Button";
import Deletepopup from "./Deletepopup";

const  DeleteContactInfo = (props) => {

    const [flag,setFlag] = useState(false);
    const [optOutText,setOptOutText] = useState("opted out successfull");
    const [optOutFlag,setOptOutFlag] = useState(false);
    const [deleteLoader,setDeleteLoader] = useState(false);

     const {firstName,lastName,title,companyName,email} = props.dataSendtoChild;
    const contactDetails = {firstName,lastName,title,companyName};
    const optout = async () =>
    {
      setFlag(false);
      setDeleteLoader(true);
      const config = {
            
        url: `${URLS.optOutByEmail}${email}`,
        method: "POST",
      };
      let response = await makeApi(config);
      if(response?.data === "opted out")
      {
        setDeleteLoader(false);
        setOptOutFlag(true);
        setOptOutText("opted out successfull");
      }
      else if(response?.data ===  "opted out not succesfull")
      {
        setDeleteLoader(false);
        setOptOutFlag(true);
        setOptOutText("opted out not succesfull");
      }
    }

  return (
    <div>
        {optOutFlag && <h1>{optOutText}</h1>}
        {!optOutFlag && <div className = "removeSEO" >
            <div className="inputbox" >
                <h2>Contact information </h2>
            </div>

            {Object.keys(contactDetails).map((key) => {
              return (
              <div className="inputbox">
                <div>{key}: </div>
                <div>{contactDetails[key]}</div>
              </div>
              )
            })}
              
            <div>
                <div className="buttonCont">
                    <Button
                        id="submit"
                        name="submit"
                        type="button"
                        className={`primaryButton ${deleteLoader ? "loading" : ""}`}
                        btnText="Delete"
                        icon={false}
                        loader={deleteLoader}
                        click={() => setFlag(true)}
                        iconName="keyboard_arrow_down"
                    />
                </div>
            </div>
            
        </div>}

        {flag && <Deletepopup 
                  changeFlagState = {() => setFlag(false)} 
                  optoutHandler = {optout} />}
        

    </div>
  )
}

export default DeleteContactInfo
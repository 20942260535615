/* eslint-disable no-param-reassign */

import axios from "axios";
import { SALESGEAR_URL } from "Static/constant";

export const URLS = {
  loginValidation: `${SALESGEAR_URL}/cm/member/isLoggedIn`,
  getCustomerSubscriptions: `${SALESGEAR_URL}/cm/admin/subscriptions/<<email>>`,
  activateSubscription: `${SALESGEAR_URL}/cm/admin/activateSubscription`,
  cancelSubscription: `${SALESGEAR_URL}/cm/admin/cancelPlan`,
  getWipeAllDataStatus: `${SALESGEAR_URL}/cm/admin/getDataRemovalJob`,
  wipeAllDataRequest: `${SALESGEAR_URL}/cm/admin/removeData`,
  getTrialUserList: `${SALESGEAR_URL}/cm/admin/getTrialUserList`,
  addMoreSeats: `${SALESGEAR_URL}/cm/admin/addSeats`,
  fetchContactByEmail : `${SALESGEAR_URL}/ss/optout/getContactByEmail/`,
  optOutByEmail :  `${SALESGEAR_URL}/ss/optout/optingOutByEmail/`,
  updateEmail : `${SALESGEAR_URL}/cm/admin/changeEmail/`,
  fetchContactByURL : `${SALESGEAR_URL}/ss/optout/getContactBySeo/`,
  fetchContactById : `${SALESGEAR_URL}/ss/optout/getContactById/`,
  extendTrialApi: `${SALESGEAR_URL}/cm/admin/extendTrial`,
  updateTeamInfo: `${SALESGEAR_URL}/cm/admin/updateTeamInfo`,
  createFreeTrial: `${SALESGEAR_URL}/cm/admin/startFreeTrial`,
  createPaidUser: `${SALESGEAR_URL}/cm/admin/createPaidUser`,
  updateStripeInfo: `${SALESGEAR_URL}/cm/admin/updateStripeInfo`,
  updatePricingOfPaidUser: `${SALESGEAR_URL}/cm/admin/updatePricing`,
  getActiveSubscriptionPlan: `${SALESGEAR_URL}/cm/subscription/getActiveSubscriptionPlans`,
  getStripeInfo:`${SALESGEAR_URL}/cm/admin/getStripeInfo`,
  updateDataSearchSettings: `${SALESGEAR_URL}/cm/admin/update/dataSearch/settings`,
  blockMember: `${SALESGEAR_URL}/ms/member/blockMember/<<email>>`,
  activateMember: `${SALESGEAR_URL}/ms/member/activateMember/<<email>>`,
  changeSubscriptionPlan: `${SALESGEAR_URL}/cm/admin/changePlan`
};

export const makeApi = (config, doCancelRequest) => {
  const { CancelToken } = axios;
  config.method = config.method || "GET";
  config.headers = {
    Accept: "application/json",
    "Content-Type": "application/json"
  };
  config.withCredentials = true;
  if (doCancelRequest !== undefined) {
    config.cancelToken = new CancelToken(function executor(cancelHttpRequest) {
      doCancelRequest(cancelHttpRequest);
    });
  }

  return axios(config)
    .then(response => {
      return response;
    })
    .catch(response => {
      return response;
    });
};

export default makeApi;

import React, { useRef, useEffect } from "react";
import StandardInputTextBox from "Components/common/StandardInputTextBox";

const EditPropertyValue = (props) => {
  const {
    id = "",
    name = "",
    value = "",
    loading = false,
    inputBoxError = "",
    editInputBox = false,
    updatedValue = "",
    previousValue = {},
    updateFunc = () => {},
    setEditInputBox = () => {},
    setUpdatedValue = () => {},
    setPreviousValue = () => {},
  } = props;

  const inputBoxRef = useRef(null);

  const onChangeSeatsCount = (_, value) => {
    setUpdatedValue(value);
  };

  const handleKeyDown = (e) => {
    if (e?.key === "Enter") {
      updateFunc();
    }
  };

  const handleCloseIcon = () => {
    setUpdatedValue(previousValue);
    setEditInputBox(false);
  };

  useEffect(() => {
    setUpdatedValue(value);
    setPreviousValue(value);
  }, [value]);

  return (
    <div className="editWrapper">
      {editInputBox ? (
        <>
          <StandardInputTextBox
            name={name}
            id={id}
            type="text"
            className={"editInputBox"}
            value={updatedValue}
            cbk={onChangeSeatsCount}
            inputBoxRef={inputBoxRef}
            focus={editInputBox}
            err={inputBoxError}
            onKeyDown={handleKeyDown}
          />
          {loading && <span className="loader"></span>}
          {!loading && (
            <i
              className="material-icons checkIcon"
              onClick={() => updateFunc()}
            >
              check
            </i>
          )}
          <i className="material-icons closeIcon" onClick={handleCloseIcon}>
            close
          </i>
        </>
      ) : (
        <div className="value">{updatedValue}</div>
      )}
      {!editInputBox && (
        <i
          className="material-icons editIcon"
          onClick={() => {
            setEditInputBox(true);
          }}
        >
          edit
        </i>
      )}
    </div>
  );
};

export { EditPropertyValue };
export default EditPropertyValue;

import React, { useState } from "react";
import { observable, action } from "mobx";
import { observer } from "mobx-react";
import Button from "Components/common/Button";

class ConfirmationPopupState {
  @observable showPopup = false;

  defaultPopupValue = {
    title: "",
    content: "",
    actionBtnText: "Confirm",
    loadingMsg: "",
    callback() {}
  };

  @observable popupValues = this.defaultPopupValue;

  @action
  setShowPopup = val => {
    this.showPopup = val;
    if (val) {
      document.getElementsByTagName("body")[0].classList.add("scrollHidden");
    } else {
      this.popupValues = this.defaultPopupValue;
      document.getElementsByTagName("body")[0].classList.remove("scrollHidden");
    }
  };

  @action
  setPopupValues = val => {
    this.popupValues = val;
    // {
    //   title: val.title ? val.title : this.popupValues.title,
    // };
  };
}
const confirmationPopupState = new ConfirmationPopupState();

const ConfirmationPopup = observer(props => {
  const [loading, setLoading] = useState(false);
  const loadingMsg = confirmationPopupState.popupValues.loadingMsg;

  const confirmClick = () => {
    confirmationPopupState.popupValues.callback(setLoadingFn);
    if (loadingMsg) {
      setLoadingFn(true);
    } else {
      confirmationPopupState.setShowPopup(false);
    }
  };

  const setLoadingFn = value => {
    setLoading(value);
  };

  const cancelAction = e => {
    const { target } = e;
    const ele = target.closest('[data-id = "notify-popup"]');
    if (ele) {
      ele.classList.remove("slideInDownTranslate");
      ele.classList.add("slideInUpTranslate");
    }
    setTimeout(() => {
      confirmationPopupState.setShowPopup(false);
    }, 200);
  };

  return confirmationPopupState.showPopup ? (
    <div className="popupWrapper">
      <div className="confirmation-popup common-popup">
        <div
          data-id="notify-popup"
          className="popup-wrap animatedFast slideInDownTranslate confirmationPopup"
        >
          {!loading ? (
            <div
              className="popup-content-wrap"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: confirmationPopupState.popupValues.content || ""
              }}
            />
          ) : (
            <div className="popup-content-wrap loaderWrapper animatedButtoon">
              <span className="loadMessage">{loadingMsg}</span>
              <Button
                id=""
                name=""
                type="button"
                className="primaryButton loading"
                btnText=""
                icon={false}
                loader={true}
                iconName="keyboard_arrow_down"
              />
            </div>
          )}
          {!loading ? (
            <div className="popup-action-wrap">
              {confirmationPopupState.popupValues &&
                (confirmationPopupState.popupValues.isCancelBtnNeeded ===
                  undefined ||
                  confirmationPopupState.popupValues.isCancelBtnNeeded ===
                    true) && (
                  <div
                    className="popup-action-btn cancelBtn"
                    onClick={e => cancelAction(e)}
                  >
                    Cancel
                  </div>
                )}
              <div
                className="popup-action-btn positive-btn"
                onClick={confirmClick}
              >
                {confirmationPopupState.popupValues.actionBtnText}
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="confirmation-popup-mask" />
    </div>
  ) : (
    ""
  );
});

export { ConfirmationPopup, confirmationPopupState };
export default ConfirmationPopup;

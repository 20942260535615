/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import "./App.scss";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "Routes/routes";
import { observer } from "mobx-react";

const App = () => {
  return (
    <Router>
      <Routes />
    </Router>
  );
};

export default observer(App);

import React from "react";

const Header = props => {
  const { title, cbk, showBackBtn } = props;
  return (
    <div className="headerCont">
      <div className="leftSection">
        <div className="titleCont">
          <span className="title">{title}</span>
        </div>
      </div>
      <div className="rightSection">
        {showBackBtn && (
          <div className="buttonCont" onClick={e => cbk && cbk(e)}>
            <div className="btn">Back</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
export { Header };

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { customerDetails } from "Stores/customerDetails";
import ContactInfo from "./ContactInfo";
import { observer } from "mobx-react";
import CustomerSubscription from "./CustomerSubscriptionDetails";
import WipeAllData from "./WipeAllData";
import UsageInfo from "./UsageInfo";

const CustomerProfile = observer(() => {
  const [subscriptionsData, setSubscriptions] = useState([]);
  const [teamData, setTeamData] = useState({});
  const [teamMember, setTeamMember] = useState([]);
  const [memberData, setMemberData] = useState([]);
  const [jobStatus, setJobStatus] = useState(null);
  const [inboxConnected, setInboxConnected] = useState(false);
  const [connectedInbox, setInboxConnectedInbox] = useState("");

  const constructCustomerData = customerData => {
    if (customerData && Object.keys(customerData).length) {
      const {
        teamSubscriptions = "",
        member = "",
        teamMembers = [],
        team = {},
        isInboxConnected,
        connectedInbox = {}
      } = customerData;
      setTeamMember(teamMembers);
      setInboxConnected(isInboxConnected);
      setInboxConnectedInbox(connectedInbox?.email);
      teamSubscriptions && setSubscriptions(teamSubscriptions);
      member && setMemberData(member);
      team && setTeamData(team);
    }
  };

  useEffect(() => {
    constructCustomerData(customerDetails.customerData);
  }, [customerDetails.customerData]);

  useEffect(() => {
    setJobStatus(customerDetails.jobStatus);
  }, [customerDetails.jobStatus]);

  return (
    <div className="customerProfileDetails">
      <ContactInfo
        memberData={memberData}
        subscriptionsData={subscriptionsData}
        teamData={teamData}
        teamMemberData={teamMember}
        connectedInbox={connectedInbox}
      />
      <UsageInfo
        teamData={teamData}
        inboxConnected={inboxConnected}
        memberData={memberData}
      />
      <CustomerSubscription data={subscriptionsData} />
      <WipeAllData data={teamMember} jobStatus={jobStatus} cbk={setJobStatus} />
    </div>
  );
});

export default CustomerProfile;
export { CustomerProfile };

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import InfoTag from "Components/common/InfoTag";

const UsageInfo = props => {
  const [teamInfo, setTeamInfo] = useState("");
  const [inboxConnected, setInboxConnected] = useState(false);
  const [memberStatus, setMemberStatus] = useState("");

  const constructTeamInfo = teamData => {
    const {
      noOfSeatsUsed,
      noOfSeatsEligible,
      noOfSequencesEligible,
      noOfSequencesUsed,
      noOfTemplatesEligible,
      noOfTemplatesUsed,
      noOfContactsEligible,
      noOfContactsUsed,
      noOfInboxesUsed,
      noOfInboxesEligible
    } = teamData;

    const newObj = {
      seats: `${noOfSeatsUsed} / ${noOfSeatsEligible}`,
      noOfSequencesCreated: `${noOfSequencesUsed} / ${noOfSequencesEligible}`,
      noOfTemplatesCreated: `${noOfTemplatesUsed} / ${noOfTemplatesEligible}`,
      noOfContactsCreated: `${noOfContactsUsed} / ${noOfContactsEligible}`,
      noOfInboxesCreated: `${noOfInboxesUsed} / ${noOfInboxesEligible}`
    };
    setTeamInfo(newObj);
  };

  const getMemberStatus = (memberData = {}) => {
    const memberStatus = memberData?.status || "";
    const statusObj = {
      0: "ACTIVE",
      1: "LOCKED",
      2: "ANONYMOUS",
      3: "UNQUALIFIED",
      4: "INCOMPLETE"
    };
    setMemberStatus(statusObj[memberStatus] || "ACTIVE");
  };

  useEffect(() => {
    setInboxConnected(props.inboxConnected);
  }, [props.inboxConnected]);

  useEffect(() => {
    if (props?.teamData) {
      constructTeamInfo(props.teamData);
    }
  }, [props.teamData]);

  useEffect(() => {
    if (props?.memberData) {
      getMemberStatus(props?.memberData);
    }
  }, [props?.memberData]);

  return (
    <div className="tableInfo">
      <div className="innerCont table">
        <div className="titleCont">
          <div className="title">Usage Information</div>
        </div>
        <div className="bodyCont">
          <InfoTag
            classValue="sequenceCreated"
            label="No of sequence created"
            value={teamInfo?.noOfSequencesCreated}
          />
          <InfoTag
            classValue="templateCreated"
            label="No of Template created"
            value={teamInfo?.noOfTemplatesCreated}
          />
          <InfoTag
            classValue="contactsAdded"
            label="No of contacts added"
            value={teamInfo?.noOfContactsCreated}
          />
          <InfoTag
            classValue="inboxCreated"
            label="No of inboxes eligible"
            value={teamInfo?.noOfInboxesCreated}
          />
          <InfoTag
            classValue="inboxConnected"
            label="Inbox connected"
            value={inboxConnected}
          />
          <InfoTag classValue="status" label="Status" value={memberStatus} />
        </div>
      </div>
    </div>
  );
};

export default UsageInfo;
export { UsageInfo };

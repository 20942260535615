import { observable, action } from "mobx";
import { makeApi, URLS } from "Utils/apiURL";
import { toasterState } from "Components/common/Toaster";
import Utils from "Utils/utils";

class CustomerDetails {
  @observable customerData = [];
  @observable customerEmail = "";
  @observable jobStatus = null;
  @observable trialUserList = [];

  @action
  setCustomerDetails = (value) => {
    this.customerData = value;
  };

  @action
  setTrialUserList = (value) => {
    this.trialUserList = value;
  };

  @action
  setCustomerEmail = (value) => {
    this.customerEmail = value;
  };

  @action
  setJobStatus = (value) => {
    this.jobStatus = value;
  };

  @action
  getCustomerDetails = async (
    email = "",
    successCbk = () => {},
    errorCbk = () => {}
  ) => {
    if (email) {
      const config = {
        url: URLS.getCustomerSubscriptions.replace("<<email>>", email),
        method: "GET",
      };
      let response = await makeApi(config);
      if (response?.data) {
        const respData = response?.data || "";
        if (respData?.success) {
          this.setCustomerDetails(response.data);
          successCbk && successCbk();
        } else {
          errorCbk && errorCbk();
        }
      } else {
        Utils.showApiFailureResponse(response);
      }
    }
  };

  @action
  getNewTrialUserList = async (dateObj, successCbk, errorCbk) => {
    try {
      const config = {
        url: URLS.getTrialUserList,
        method: "POST",
        data: dateObj,
      };
      let response = await makeApi(config);
      if (response && response.data) {
        const respData = response.data;
        if (respData) {
          this.setTrialUserList(response.data);
          successCbk && successCbk();
        } else {
          errorCbk && errorCbk();
        }
      } else {
        Utils.showApiFailureResponse(response);
      }
    } catch (error) {
      console.error("Filter Data error ", error);
    }
  };

  @action
  getWipeAllDataStatus = async (email) => {
    try {
      const config = {
        url: `${URLS.getWipeAllDataStatus}?email=${email}`,
        method: "GET",
      };
      let response = await makeApi(config);
      if (response && response.data) {
        this.setJobStatus(response.data);
      }
    } catch (error) {
      console.error("Get Wipe All Data Fail", error);
    }
  };

  @action
  wipeAllDataRequest = async (email, cbkLoader) => {
    try {
      const config = {
        url: `${URLS.wipeAllDataRequest}`,
        method: "POST",
        data: { email },
      };
      let response = await makeApi(config);
      if (response && response.data && response.data.status === 200) {
        toasterState.setToastMsg(
          "Wipe All Data requested successfully. Activity INPROGRESS",
          "success"
        );
      } else {
        Utils.showApiFailureResponse(response);
      }
      cbkLoader(false);
    } catch (error) {
      console.error("Wipe All Data Request Fail", error);
    }
  };
}

const customerDetails = new CustomerDetails();
export { CustomerDetails, customerDetails };
export default CustomerDetails;

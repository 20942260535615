import React from "react";
import Button from "../../../Components/common/Button";

const FormActionButtons = (props) => {
  const {
    submitBtnText = "",
    cancelBtnText = "",
    submitBtnClsName = "primaryButton",
    cancelBtnClsName = "secondaryButton",
    submit = () => {},
    cancel = () => {},
    submitButtonLoader = false
  } = props;

  const submitBtnAction = () => submit();
  const cancelBtnAction = () => cancel();

  const buttonList = [
    {
      id: "createSubscription",
      name: "createSubscription",
      btnText: submitBtnText || "Create",
      type: "submit",
      className: submitBtnClsName,
      method: submitBtnAction,
      loading: submitButtonLoader,
    },
    {
      id: "cancelConfigure",
      name: "cancelConfigure",
      btnText: cancelBtnText || 'Cancel',
      type: "button",
      className: cancelBtnClsName,
      method: cancelBtnAction,
      loading: false,
    },
  ];

  return (
    <div className="submitButton">
      {buttonList?.map(item => {
        const {
          id = "",
          name = "",
          btnText = "",
          type = "",
          className = "",
          method = () => {},
          loading = false,
          disabled = false
        } = item || {};
        return (
          <Button
            key={id}
            id={id}
            name={name}
            btnText={btnText}
            type={type}
            className={className}
            click={method}
            loader={loading}
            disabled={disabled}
          />
        );
      })}
    </div>
  );
};

export { FormActionButtons };
export default FormActionButtons;

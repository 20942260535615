export let WEB_URL = "http://localhost:8080"; //salesgear web
export let SALESGEAR_URL = "http://localhost:9000"; //api gateway

if (process.env.BUILD_ENV === "local") {
  WEB_URL = "http://localhost:8080";
  SALESGEAR_URL = "http://localhost:9000";
} else if (process.env.BUILD_ENV === "staging") {
  WEB_URL = "https://www.salesgearqe.com";
  SALESGEAR_URL = "https://core.salesgearqe.com";
} else if (process.env.BUILD_ENV === "production") {
  WEB_URL = "https://www.salesgear.io";
  SALESGEAR_URL = "https://core.salesgear.io";
}

export const TOASTER_COLOR = {
  DANGER: "#E54C3C",
  SUCCESS: "#28ad56",
  WARNING: "#ff7800",
  INFO: "#282b2c"
};

const ConstantObj = {
  months: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ]
};

export default ConstantObj;
export { ConstantObj };

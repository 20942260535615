import React, { useState, useEffect } from "react";
import { customerDetails } from "Stores/customerDetails";

const TrialUserList = () => {
  const [newTrialUsers, setNewTrialUsers] = useState([]);

  useEffect(() => {
    setNewTrialUsers(customerDetails.trialUserList.result);
  }, [customerDetails.trialUserList]);

  return (
    <div className="tableInfo trialUserList">
      {newTrialUsers?.length > 0 ? (
        <div className="innerCont table">
          <>
            <div className="titleCont">
              <div className="title">New trial users</div>
              <span className="totalCount">{`Total users: ${newTrialUsers?.length}`}</span>
            </div>
            <table className="bodyCont tableContainer">
              <thead>
                <tr>
                  <th>Email</th>
                  <th>Team size</th>
                  <th>No of sequences used</th>
                  <th>No of templates used</th>
                  <th>No of contacts used</th>
                  <th>Total emails sent</th>
                  <th>Trial end date</th>
                </tr>
              </thead>
              <tbody>
                {newTrialUsers?.map((item, index) => {
                  const { team, emailInsights = {} } = item;
                  return (
                    <tr key={`${index}-${team.email}`}>
                      <td className="email">{team.email}</td>
                      <td className="teamSize">{`${team.noOfSeatsUsed} / ${team.noOfSeatsEligible}`}</td>
                      <td className="noOfSequences">{`${team.noOfSequencesUsed} / ${team.noOfSequencesEligible}`}</td>
                      <td className="noOfTemplates">{`${team.noOfTemplatesUsed} / ${team.noOfTemplatesEligible}`}</td>
                      <td className="noOfContacts">{`${team.noOfContactsUsed} / ${team.noOfContactsEligible}`}</td>
                      <td className="sentCount">
                        {emailInsights?.sentCount || 0}
                      </td>
                      <td className="trialEndDate">
                        {team?.trialEndDate?.substring(0, 10)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </>
        </div>
      ) : (
        <div className="noNewUser">
          Sorry, there are no trial users within this date range
        </div>
      )}
    </div>
  );
};

export { TrialUserList };
export default TrialUserList;

import React from 'react';
import Button from "Components/common/Button";

function Deletepopup(props) {
  return (
    <div  id="myModal" className="modal">
        <div className="modal-content">

          <div className="modal-header">
            <span className="close" onClick={props.changeFlagState} >&times;</span>
            <h4>Are you sure want to remove this contact from the database</h4>
          </div>
          
          <div className="modal-body">
            
            <div className="buttonCont" style={{ marginRight: '5%'}}>
                    <Button
                        id="submit"
                        name="submit"
                        type="button"
                        className={`primaryButton`}
                        btnText="Yes"
                        icon={false}
                        
                        click={props.optoutHandler}
                        iconName="keyboard_arrow_down"
                    />
                </div>
                <div className="buttonCont">
                    <Button
                        id="submit"
                        name="submit"
                        type="button"
                        className={`primaryButton`}
                        btnText="No"
                        icon={false}
                        
                        click={props.changeFlagState}
                        iconName="keyboard_arrow_down"
                    />
                </div>
          </div>

          

        </div>
      </div> 
  )
}

export default Deletepopup
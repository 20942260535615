import React, { useState } from "react";
import StandardInputTextBox from "Components/common/StandardInputTextBox";
import Button from "Components/common/Button";
import makeApi, { URLS } from "Utils/apiURL";
import { toasterState } from "Components/common/Toaster";
import Utils from "Utils/utils";
import { customerDetails } from "Stores/customerDetails";

export const ExtendTrial = props => {
  const { teamSubscriptionId = "", email = "" } = props;
  const [loader, setLoader] = useState(false);
  const [date, setDate] = useState("");
  const [dateErr, setDateErr] = useState("");

  const extendTrial = async event => {
    event.preventDefault();
    if (date && Utils.getDateDiff(date) > -1) {
      setLoader(true);
      try {
        const config = {
          url: URLS.extendTrialApi,
          method: "POST",
          data: {
            trialEndDate: new Date(date).getTime(),
            teamSubscriptionId
          }
        };
        const res = await makeApi(config);
        if (res?.data) {
          customerDetails.getCustomerDetails(email);
          toasterState.setToastMsg("Successfully Extended Trial", "success");
          setDate("");
        } else {
          Utils.showApiFailureResponse(res);
        }
        setLoader(false);
      } catch (err) {
        setLoader(false);
      }
    } else if (date && Utils.getDateDiff(date) <= -1) {
      setDateErr("Please select a today or greater date");
      setTimeout(() => {
        setDateErr("");
      }, 1500);
    } else {
      setDateErr("Please select a valid date");
      setTimeout(() => {
        setDateErr("");
      }, 1500);
    }
  };

  const changeDate = (id = "", value = "") => {
    setDate(value);
  };

  return (
    <div className="extendTrial">
      <StandardInputTextBox
        name="trialEndDate"
        id="trialEndDate"
        cbk={changeDate}
        err={dateErr}
        type="date"
        readOnly={false}
        value={date}
      />
      <div className="btnSection">
        <Button
          id="extendTrialBtn"
          name="extendTrialBtn"
          type="button"
          className={`primaryButton ${loader ? "loading" : ""}`}
          btnText="Extend Trial"
          icon={false}
          loader={loader}
          click={e => extendTrial(e)}
          iconName="keyboard_arrow_down"
        />
      </div>
    </div>
  );
};

export default ExtendTrial;

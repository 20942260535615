import React, { useState } from "react";
import Utils from "Utils/utils";
import { makeApi, URLS } from "Utils/apiURL";
import EditPropertyValue from "./EditPropertyValue";

const EditPricing = (props) => {
  const {
    email = "",
    value = "",
    setActiveSubscriptionsData = () => {},
  } = props || {};

  const [loading, setLoading] = useState(false);
  const [inputBoxError, setInputBoxError] = useState("");
  const [editInputBox, setEditInputBox] = useState(false);
  const [previousSeatsValue, setPreviousSeatsValue] = useState("");
  const [updatedPricing, setUpdatedPricing] = useState(value);

  const updateSeatsCount = async () => {
    if (updatedPricing) {
      setLoading(true);
      const config = {
        url: URLS.updatePricingOfPaidUser,
        method: "POST",
        data: { totalPricing: updatedPricing, email: email },
      };
      let response = await makeApi(config);
      if (response?.data) {
        const { price = 0 } = response?.data || {};
        setLoading(false);
        setActiveSubscriptionsData(response?.data);
        setUpdatedPricing(price);
        setPreviousSeatsValue(price);
        setEditInputBox(false);
        setInputBoxError("");
      } else {
        Utils.showApiFailureResponse(response);
      }
    } else {
      setTimeout(() => {
        setInputBoxError("");
      }, 2000);
      setInputBoxError("Please provide valid pricing value");
    }
  };

  return (
    <EditPropertyValue
      value={value}
      id="editPricing"
      name="editPricing"
      loading={loading}
      editInputBox={editInputBox}
      updatedValue={updatedPricing}
      updateFunc={updateSeatsCount}
      inputBoxError={inputBoxError}
      setEditInputBox={setEditInputBox}
      previousValue={previousSeatsValue}
      setUpdatedValue={setUpdatedPricing}
      setPreviousValue={setPreviousSeatsValue}
    />
  );
};

export { EditPricing };
export default EditPricing;

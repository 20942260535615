import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import Utils from "Utils/utils";
import { makeApi, URLS } from "Utils/apiURL";
import StandardInputTextBox from "Components/common/StandardInputTextBox";
import { toasterState } from "Components/common/Toaster";
import { settingsPopupState } from "./SettingsPopup";
import FormActionButtons from "./FormActionButtons";
import { customerDetails } from "Stores/customerDetails";

const UpdateSubscriptionPlan = observer((props) => {
  const {
    email = "",
    activeLabelList = {},
    setActiveLabelList = () => {},
  } = props || {};
  const [buttonLoader, setButtonLoader] = useState(false);

  const dataConfig = [
    {
      label: "Stripe customer Id",
      value: "stripeCustomerId",
      type: "text",
    },
    {
      label: "Stripe Subscription Id",
      value: "stripeSubscriptionId",
      type: "text",
    },
  ];

  const updateSubscriptionValue = async (data = {}, email = "") => {
    if (email) {
      const config = {
        url: URLS.updateStripeInfo,
        method: "POST",
        data: { ...data, email: email },
      };
      let response = await makeApi(config);
      if (response?.data) {
        const { stripeData = {} } = response?.data || {};
        setButtonLoader(false);
        settingsPopupState.setShowPopup(false);
        customerDetails.getCustomerDetails(customerDetails.customerEmail);
        setActiveLabelList({
          ...activeLabelList,
          stripeCustomerId: stripeData?.customerId,
          stripeSubscriptionId: stripeData?.subscriptionId,
        });
        toasterState.setToastMsg("Stripe Data updated successfully", "success");
      } else {
        Utils.showApiFailureResponse(response);
        settingsPopupState.setShowPopup(false);
      }
    } else {
      setButtonLoader(false);
    }
  };

  const handleUpdateSubscription = (e) => {
    e.preventDefault();
    setButtonLoader(true);
    const { stripeCustomerId = '', stripeSubscriptionId = '' } = activeLabelList;
    const config = { stripeCustomerId, stripeSubscriptionId };
    updateSubscriptionValue(config, email);
  };

  const handleChange = (value = "", event) => {
    setActiveLabelList({
      ...activeLabelList,
      [value]: event?.currentTarget?.value,
    });
  };

  const handleInputBoxChange = (label = "", value) => {
    setActiveLabelList({
      ...activeLabelList,
      [label]: value,
    });
  };

  useEffect(() => {
    setActiveLabelList({
      ...activeLabelList,
      stripeCustomerId: activeLabelList?.stripeCustomerId,
      stripeSubscriptionId: activeLabelList?.stripeSubscriptionId,
    });
  }, [
    activeLabelList?.stripeCustomerId,
    activeLabelList?.stripeSubscriptionId,
  ]);

  return (
    <form
      className="updateSubscriptionWrapper"
      onSubmit={handleUpdateSubscription}
      onCancel={() => settingsPopupState?.setShowPopup(false)}
    >
      {dataConfig.map((item) => {
        const {
          label = "",
          value: itemValue = "",
          type = "",
          options = [],
          min = 0,
        } = item || {};
        return (
          <div className="dataContainer" key={itemValue}>
            <div key={itemValue}>{label}</div>
            {type === "dropdown" ? (
              <select
                value={activeLabelList[itemValue]}
                onChange={(e) => handleChange(itemValue, e)}
              >
                {options?.map((option) => {
                  const { value = "" } = option || {};
                  return (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  );
                })}
              </select>
            ) : (
              <StandardInputTextBox
                name={itemValue}
                id={itemValue}
                type={type}
                className={"inputBox"}
                value={activeLabelList?.[itemValue]}
                cbk={handleInputBoxChange}
                min={min}
              />
            )}
          </div>
        );
      })}
      <FormActionButtons
        submitBtnText="Update"
        submitBtnClsName={`primaryButton updateSubscriptionButton ${
          buttonLoader ? "loading" : ""
        }`}
        cancelBtnClsName="secondaryButton cancelButton"
        cancel={() => settingsPopupState?.setShowPopup(false)}
        submitButtonLoader={buttonLoader}
      />
    </form>
  );
});

export { UpdateSubscriptionPlan };
export default UpdateSubscriptionPlan;

import React, { useState } from "react";
import Button from "../../../Components/common/Button";
import { toasterState } from "Components/common/Toaster";
import { makeApi, URLS } from "Utils/apiURL";
import { customerDetails } from "Stores/customerDetails";

const CreateTrialUser = (props) => {
  const { teamData = {} } = props;
  const [buttonLoader, setButtonLoader] = useState(false);

  const buttonCbk = async () => {
    setButtonLoader(true);
    try {
      const config = {
        url: URLS.createFreeTrial,
        method: "POST",
        data: { businessEmail: teamData?.email },
      };
      const { status = "", data: responseData = {} } = await makeApi(config);
      const { data = "" } = responseData || {};
      if (
        ["200", 200].includes(status || data?.statusCode) &&
        data?.status === "success"
      ) {
        customerDetails.getCustomerDetails(teamData?.email, () =>
          setButtonLoader(false)
        );
        toasterState.setToastMsg("Trial created successfully", "success");
      } else {
        toasterState.setToastMsg("Trial creation failed ", "failure");
        setButtonLoader(false);
      }
    } catch (err) {
      console.error("Start free trail error", err);
    }
  };

  return (
    <div>
      <Button
        id="createTrial"
        name="createTrial"
        type="button"
        className={`primaryButton ${buttonLoader ? "loading" : ""}`}
        btnText="Create Trial"
        icon={false}
        loader={buttonLoader}
        click={buttonCbk}
      />
    </div>
  );
};

export { CreateTrialUser };
export default CreateTrialUser;

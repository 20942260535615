import React, { useState, useEffect } from "react";
import { toasterState } from "Components/common/Toaster";
import Button from "Components/common/Button";
import makeApi, { URLS } from "Utils/apiURL";
import Utils from "Utils/utils";
import { settingsPopupState } from "./SettingsPopup";
import FormActionButtons from "./FormActionButtons";

const ChangeSubscriptionPopupContent = (props) => {
  const {
    email = "",
    activeLabelList = {},
    setActiveLabelList = () => {},
    planDetails = {},
  } = props || {};

  const [buttonLoader, setButtonLoader] = useState(false);
  const [activePlans, setActivePlans] = useState([]);

  const dataConfig = [
    {
      label: "Plan name",
      value: "changePlanName",
      type: "dropdown",
      options: [
        { label: "Basic", value: "basic" },
        { label: "Pro", value: "pro" },
        { label: "Unlimited", value: "unlimited" },
      ],
    },
    {
      label: "Plan Type",
      value: "changePlanType",
      type: "dropdown",
      options: [
        { label: "Monthly", value: "monthly" },
        { label: "Annual", value: "annual" },
      ],
    },
  ];

  const updateSubscriptionValue = async (data = {}, email = "") => {
    if (email) {
      const config = {
        url: URLS.createPaidUser,
        method: "POST",
        data: { ...data, email: email },
      };
      let response = await makeApi(config);
      if (response?.data) {
        setButtonLoader(false);
        settingsPopupState.setShowPopup(false);
        toasterState.setToastMsg(
          "Subscriptions changed successfully",
          "success"
        );
      } else {
        Utils.showApiFailureResponse(response);
        settingsPopupState.setShowPopup(false);
      }
    } else {
      setButtonLoader(false);
    }
  };

  const handleUpdateSubscription = (e) => {
    e.preventDefault();
    setButtonLoader(true);
    const { changePlanType: planType = '', changePlanName: planName = '' } = activeLabelList || {};
    const currentSelectedPlan = activePlans.filter(
      (item) => item.displayName?.toLowerCase() === planName?.toLowerCase()
    )[0];
    const config = {};
    if (planType === "annual") {
      config.planId = currentSelectedPlan?.annualPlanId;
      config.interval = 12;
      config.annual = true;
    } else {
      config.planId = currentSelectedPlan?.monthlyPlanId;
      config.interval = 1;
      config.annual = false;
    }
    updateSubscriptionValue(config, email);
  };

  const handleChange = (value = "", event) => {
    setActiveLabelList({
      ...activeLabelList,
      [value]: event?.currentTarget?.value,
    });
  };

  const getActiveSubscriptionPlans = async () => {
    const config = {
      url: URLS.getActiveSubscriptionPlan,
      method: "GET",
    };
    let response = await makeApi(config);
    if (response?.data) {
      setActivePlans(response?.data);
    }
  };

  useEffect(() => {
    if (planDetails && Object.keys(planDetails)?.length > 0) {
      const { planName: changePlanName = "", planType: changePlanType = "" } =
        planDetails || {};
      setActiveLabelList({
        ...activeLabelList,
        changePlanName: changePlanName?.toLowerCase(),
        changePlanType: changePlanType?.toLowerCase(),
      });
    }
  }, [planDetails]);

  useEffect(() => {
    getActiveSubscriptionPlans();
  }, []);

  return (
    <form
      className="changeSubscriptionWrapper"
      onSubmit={handleUpdateSubscription}
      onCancel={() => settingsPopupState?.setShowPopup(false)}
    >
      {dataConfig.map((item) => {
        const {
          label = "",
          value: itemValue = "",
          options = [],
        } = item || {};
        return (
          <div className="dataContainer" key={itemValue}>
            <div key={itemValue}>{label}</div>
            <select
              value={activeLabelList[itemValue]}
              onChange={(e) => handleChange(itemValue, e)}
            >
              {options?.map((option) => {
                const { value = "" } = option || {};
                return (
                  <option key={value} value={value}>
                    {value}
                  </option>
                );
              })}
            </select>
          </div>
        );
      })}
      <FormActionButtons
        submitBtnText="Update Plan"
        submitBtnClsName={`primaryButton updateSubscriptionButton ${
          buttonLoader ? "loading" : ""
        }`}
        cancelBtnClsName="secondaryButton cancelButton"
        cancel={() => settingsPopupState?.setShowPopup(false)}
        submitButtonLoader={buttonLoader}
      />
    </form>
  );
};

const ChangeSubscriptionPlan = (props) => {
  const { source = "changeSubscription" } = props || {};

  const handleChangeSubscription = () => {
    settingsPopupState.setShowPopup(true);
    settingsPopupState.setDefaultPopupvalue({
      cbk: () => settingsPopupState.setShowPopup(false),
      source,
    });
  };

  return (
    <Button
      id="changeSubscription"
      name="changeSubscription"
      type="button"
      className="primaryButton changeSubscriptionButton"
      btnText="Change Subscription"
      icon={false}
      loader={false}
      click={handleChangeSubscription}
    />
  );
};

export { ChangeSubscriptionPlan, ChangeSubscriptionPopupContent };
export default ChangeSubscriptionPlan;

import React, { useState } from "react";
import DeleteContactInfo from "./DeleteContactInfo";
import { makeApi, URLS } from "Utils/apiURL";
import Button from "Components/common/Button";

function DeleteSEO() {

    const [showdeletecontactInfo, setdeletecontactInfo] = useState(true);
    const [emailflag,setemail] = useState(true);
    const [urlflag,seturl] = useState(false);
    const [idflag,setid] = useState(false);
    const [email,setemailvalue] = useState("");
    const [url,seturlvalue] = useState("");
    const [id,setidvalue] = useState("");
    const [deletinfo,setDeleteInfo] = useState(false);
    const [buttonLoader,setButtonLoader] = useState(false);
    const [dataSendtoChild,setDataSendToChild] = useState({});
    const [contactInfoNotPresent,setContactInfoNotPresent] = useState(false);

    const handleChange = (e) => {
        setDeleteInfo(false);
        setContactInfoNotPresent(false);
        if(e.target.value === "email")
        {
            setemail(true);
            seturl(false);
            setid(false);
        }
        else if(e.target.value === "URL")
        {
            setemail(false);
            seturl(true);
            setid(false);
        }
        else if(e.target.value === "ID")
        {
            setemail(false);
            seturl(false);
            setid(true);
        }
    }

    const apicall = async (url,param) =>
    {
        const config = {
            
            url: `${url}${param}`,
            method: "GET",
          };
          let response = await makeApi(config);
          setDataSendToChild(response);

          if(response.data.length === 0)
          {
            setContactInfoNotPresent(true);
            setButtonLoader(false);
          }
          else
          {
            const {email,firstName,lastName,title,companyName} = response.data;
            const dataSendtoChild = {email,firstName,lastName,title,companyName}||{};
            setDataSendToChild(dataSendtoChild);
            setContactInfoNotPresent(false);
            setDeleteInfo(true);
            setButtonLoader(false);
          }
    }

    const onSubmit = () => 
    {
        setContactInfoNotPresent(false);
        setDeleteInfo(false);
        setButtonLoader(true);
        if(emailflag)
        {
            apicall(URLS.fetchContactByEmail,email);
        }
        else if(urlflag)
        {
            apicall(URLS.fetchContactByURL,url);   //seoname
            
        }
        else if(idflag)
        {
            apicall(URLS.fetchContactById,id);
        }
    }


    
    return (
    <div>
        <div className = "removeSEO" >
            <div>
                <h2>Delete SEO contacts</h2>
            </div>

            <div className="inputbox">
                <span>Select option to delete</span>
                <span>
                    <select style={{width : '170px' }}  onChange={handleChange} >
                        <option value = "email">email</option>
                        <option value = "URL">URL</option>
                        <option value= "ID">ID</option>
                    </select>
                </span> 
            </div>

            {emailflag && <div className="inputbox">
                <span>email :</span>
                <span>
                    <input value={email} onChange = {(e) => setemailvalue(e.target.value)} ></input>
                    </span> 
            </div>}

            {urlflag && <div className="inputbox">
                <span>URL :</span>
                <span>
                    <input value = {url} onChange = {(e) => seturlvalue(e.target.value)}></input>
                </span> 
            </div>}

            {idflag && <div className="inputbox">
                <span>ID :</span>
                <span>
                    <input value = {id} onChange = {(e) => setidvalue(e.target.value)} ></input>
                </span> 
            </div>}

            <div>
                <div className="buttonCont">
                    <Button
                        id="submit"
                        name="submit"
                        type="button"
                        className={`primaryButton ${buttonLoader ? "loading" : ""}`}
                        btnText="Submit"
                        icon={false}
                        loader={buttonLoader}
                        click={onSubmit}
                        iconName="keyboard_arrow_down"
                    />
                </div>
            </div>
            
        </div>
        {contactInfoNotPresent && <div><h1>Contact information is not available</h1></div>}
        {deletinfo && !contactInfoNotPresent && <DeleteContactInfo dataSendtoChild = {dataSendtoChild} />}
        
    </div>
   
  )
}

export default DeleteSEO
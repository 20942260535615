import React from "react";
import Button from "./Button";

const InfoTag = props => {
  const { showSideButton, cbk, classValue, label, value, loader } = props;
  
  return !loader ? (
    value || value === false || value === 0 ? (
      <div className={`dataCont ${classValue}`}>
        <div className="label">{label}</div>
        <span className="separator">:</span>
        <div className="value">{`${value}`}</div>
        {showSideButton && (
          <Button
            id="activate"
            name="activate"
            type="button"
            className="activatePlan secondaryButton"
            btnText="Activate"
            click={cbk}
            icon={false}
            loader={false}
            iconName="keyboard_arrow_down"
          />
        )}
      </div>
    ) : (
      ""
    )
  ) : (
    <InfoTagLoader />
  );
};

const InfoTagLoader = () => {
  return (
    <div className="dataCont">
      <span
        className="linear-background"
        style={{ width: "90%", height: "12px" }}
      ></span>
      <span></span>
      <span
        className="linear-background"
        style={{ width: "90%", height: "12px" }}
      ></span>
    </div>
  );
};

export default InfoTag;
export { InfoTag };

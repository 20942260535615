import React from "react";
import { observer } from "mobx-react";
import Button from "Components/common/Button";
import { settingsPopupState } from "./SettingsPopup";

const CreateOrUpdateSubscriptionBtn = observer((props) => {
  const { source = "createSubscription", btnText = "Create subscription" } =
    props;

  const buttonCbk = async () => {
    settingsPopupState.setShowPopup(true);
    settingsPopupState.setDefaultPopupvalue({
      cbk: () => settingsPopupState.setShowPopup(false),
      source,
    });
  };

  return (
    <Button
      id={source}
      name={source}
      type="button"
      className={`primaryButton ${source}`}
      btnText={btnText}
      icon={false}
      loader={false}
      click={buttonCbk}
    />
  );
});

export { CreateOrUpdateSubscriptionBtn };
export default CreateOrUpdateSubscriptionBtn;

/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import DashboardModule from "Pages/Dashboard/DashboardModule";
import { loginValidate } from "Utils/commonAPI.js";
import userDetail from "Stores/userDetail";
import JumpBallLoader from "Components/common/JumpBallLoader";
import MemberUnAuthorized from "Components/common/MemberUnAuthorized";

const Routes = (props) => {
  const [isLoggedIn, setIsLoggedIn] = useState("");
  const [role, setRole] = useState("");

  const loginCheck = async () => {
    await loginValidate();
    setIsLoggedIn(userDetail.userInfo.userLoggedIn);
    setRole(userDetail.userInfo.role);
  };

  useEffect(() => {
    loginCheck();
  }, []);

  return isLoggedIn === true ? (
    role === 200 || role === 100 ? (
      <Switch>
        <Route path="/" render={() => <DashboardModule />} />
      </Switch>
    ) : (
      <Route path="/" render={() => <MemberUnAuthorized />} />
    )
  ) : isLoggedIn === false ? (
    <div className="loginCont">Please Login to continue</div>
  ) : (
    <JumpBallLoader />
  );
};

export default Routes;
export { Routes };

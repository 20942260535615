import React, { useState, useEffect } from "react";
import Utils from "Utils/utils";
import { makeApi, URLS } from "Utils/apiURL";
import { toasterState } from "Components/common/Toaster";
import StandardInputTextBox from "Components/common/StandardInputTextBox";
import { settingsPopupState } from "./SettingsPopup";
import FormActionButtons from "./FormActionButtons";
import { customerDetails } from "Stores/customerDetails";

const CreateSubscriptionPlan = (props) => {
  const { email = "", teamData = {} } = props;
  const [buttonLoader, setButtonLoader] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [activeLabelList, setActiveLabelList] = useState({
    planName: "basic",
    planType: "monthly",
    noOfSeats: 1,
    totalPricing: 0,
  });
  const [activePlans, setActivePlans] = useState([]);

  const dataConfig = [
    {
      label: "Number of seats",
      value: "noOfSeats",
      type: "number",
      min: teamData?.noOfSeatsUsed,
    },
    {
      label: "Renewal Date",
      value: "renewalDate",
      type: "date",
    },
    {
      label: "Total Pricing",
      value: "totalPricing",
      type: "text",
    },
    {
      label: "Plan name",
      value: "planName",
      type: "dropdown",
      options: [
        { label: "Basic", value: "basic" },
        { label: "Pro", value: "pro" },
        {label : "Unlimited", value : "unlimited"}
      ],
    },
    {
      label: "Plan Type",
      value: "planType",
      type: "dropdown",
      options: [
        { label: "Monthly", value: "monthly" },
        { label: "Annual", value: "annual" },
      ],
    },
  ];

  const updateSubscriptionValue = async (data = {}, email = "") => {
    if (email) {
      const config = {
        url: URLS.createPaidUser,
        method: "POST",
        data: { ...data, email: email },
      };
      let response = await makeApi(config);
      if (response?.data) {
        setButtonLoader(false);
        settingsPopupState.setShowPopup(false);
        customerDetails.getCustomerDetails(customerDetails.customerEmail);
        toasterState.setToastMsg(
          "Subscriptions updated successfully",
          "success"
        );
      } else {
        Utils.showApiFailureResponse(response);
        settingsPopupState.setShowPopup(false);
      }
    } else {
      setButtonLoader(false);
    }
  };

  const checkForMandatoryFields = () => {
    const { noOfSeats = 0, renewalDate = '', totalPricing = 0 } = activeLabelList;
    let valid = false;
    if (renewalDate && noOfSeats && (totalPricing === 0 || totalPricing)) {
      setErrorMsg("");
      valid = true;
    } else if (noOfSeats < teamData?.noOfSeatsUsed) {
      setErrorMsg(
        `Number of seats must be greater than or equal to ${teamData?.noOfSeatsUsed}`
      );
    } else if (!renewalDate) {
      setErrorMsg("Please provide valid renewal date");
    } else if (totalPricing !== 0 && !totalPricing) {
      setErrorMsg("Please provide valid pricing");
    }
    return valid;
  };

  const handleUpdateSubscription = (e) => {
    e.preventDefault();
    const validFields = checkForMandatoryFields();
    if (validFields) {
      setButtonLoader(true);
      const {
        planType,
        planName,
        totalPricing = 0,
        noOfSeats,
        renewalDate,
      } = activeLabelList;

      const currentSelectedPlan = activePlans.filter(
        (item) => item.displayName?.toLowerCase() === planName?.toLowerCase()
      )[0];
      const config = {
        numberOfSeats: noOfSeats,
        renewalDate,
        totalPricing,
      };
      if (planType === "annual") {
        config.planId = currentSelectedPlan?.annualPlanId;
        config.interval = 12;
        config.annual = true;
      } else {
        config.planId = currentSelectedPlan?.monthlyPlanId;
        config.interval = 1;
        config.annual = false;
      }
      updateSubscriptionValue(config, email);
    }
  };

  const handleChange = (value = "", event) => {
    setActiveLabelList({
      ...activeLabelList,
      [value]: event?.currentTarget?.value,
    });
  };

  const handleInputBoxChange = (label = "", value) => {
    setActiveLabelList({
      ...activeLabelList,
      [label]: value,
    });
  };

  const getActiveSubscriptionPlans = async () => {
    const config = {
      url: URLS.getActiveSubscriptionPlan,
      method: "GET",
    };
    let response = await makeApi(config);
    if (response?.data) {
      setActivePlans(response?.data);
    }
  };

  useEffect(() => {
    if (errorMsg) {
      setTimeout(() => {
        setErrorMsg("");
      }, 1000);
    }
  }, [errorMsg]);

  useEffect(() => {
    setActiveLabelList({
      ...activeLabelList,
      noOfSeats: teamData?.noOfSeatsUsed,
    });
  }, [teamData?.noOfSeatsUsed]);

  useEffect(() => {
    getActiveSubscriptionPlans();
  }, []);

  return (
    <form
      className="updateSubscriptionWrapper"
      onSubmit={handleUpdateSubscription}
      onCancel={() => settingsPopupState?.setShowPopup(false)}
    >
      {dataConfig.map((item) => {
        const {
          label = "",
          value: itemValue = "",
          type = "",
          options = [],
          min = 0,
        } = item || {};
        return (
          <div className="dataContainer" key={itemValue}>
            <div key={itemValue}>{label}</div>
            {type === "dropdown" ? (
              <select
                value={activeLabelList[itemValue]}
                onChange={(e) => handleChange(itemValue, e)}
              >
                {options?.map((option) => {
                  const { value = "" } = option || {};
                  return (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  );
                })}
              </select>
            ) : (
              <StandardInputTextBox
                name={itemValue}
                id={itemValue}
                type={type}
                className={"inputBox"}
                value={activeLabelList?.[itemValue]}
                cbk={handleInputBoxChange}
                min={min}
              />
            )}
          </div>
        );
      })}
      {errorMsg && <div className="errorMessage">{errorMsg}</div>}
      <FormActionButtons
        submitBtnClsName={`primaryButton createSubscriptionButton ${
          buttonLoader ? "loading" : ""
        }`}
        cancelBtnClsName="secondaryButton cancelButton"
        cancel={() => settingsPopupState?.setShowPopup(false)}
        submitButtonLoader={buttonLoader}
      />
    </form>
  );
};

export { CreateSubscriptionPlan };
export default CreateSubscriptionPlan;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { makeApi, URLS } from "Utils/apiURL";
import InfoTag from "Components/common/InfoTag";
import EditSeats from "Components/common/EditSeats";
import EditPricing from "Components/common/EditPricing";
import ExtendTrial from "./ExtendTrial";
import Button from "Components/common/Button";
import { settingsPopupState } from "./SettingsPopup";
import UpdateUserFeatureFlag from "./UpdateUserFeatureFlag";
import CreateTrialUser from "./CreateTrialUser";
import SettingsPopup from "./SettingsPopup";
import CreateOrUpdateSubscriptionBtn from "./CreateOrUpdateSubscriptionBtn";
import CreateSubscriptionPlan from "./CreateSubscriptionPlan";
import UpdateSubscriptionPlan from "./UpdateSubscriptionPlan";
import DiscoverSettings from "../DiscoverSettings/index";
import EditEmail from "./editEmail";
import ChangeSubscriptionPlan, {
  ChangeSubscriptionPopupContent,
} from "./ChangeSubscriptionPlan";
import CancelSubscriptionPlan from "./CancelSubscriptionPlan";
import { toasterState } from "Components/common/Toaster";
import { confirmationPopupState } from "Components/common/ConfirmationPopup";
import Utils from "Utils/utils";

const ContactInfo = observer((props) => {
  const {
    subscriptionsData = "",
    memberData = "",
    teamData = "",
    teamMemberData = "",
    connectedInbox = "",
  } = props || {};
  const { id: teamSubscriptionId = "" } =
    (subscriptionsData?.length && subscriptionsData[0]) || "";

  const [personalInfo, setPersonalInfo] = useState("");
  const [subscriptionInfo, setSubscriptionInfo] = useState({});
  const [teamInfo, setTeamInfo] = useState("");
  const [teamMemberInfo, setTeamMemberInfo] = useState("");
  const [activeLabelList, setActiveLabelList] = useState({});
  const [activeSubscriptionsData, setActiveSubscriptionsData] = useState({});
  const [editEmailFlag, setEditEmailFlag] = useState(true);
  const [email, setEmail] = useState("");
  const [planDetails, setPlanDetails] = useState({});

  const constructPersonalData = (memberData) => {
    if (memberData && Object.keys(memberData).length) {
      const {
        email = "",
        source,
        createTs = "",
        name = "",
        firstName = "",
        lastName = "",
        title = "",
        phone = "",
      } = memberData || {};
      const newObj = {
        email,
        source,
        title,
        signupDate: Utils.formatDate(createTs),
        name: getCustomerName(name, firstName, lastName),
        phone,
      };
      setPersonalInfo(newObj);
    }
  };

  const constructTeamMemberData = (teamMemberData) => {
    const teamAdminData = teamMemberData[0];
    const newObj = {
      name: getCustomerName(
        teamAdminData?.name,
        teamAdminData?.firstName,
        teamAdminData?.lastName
      ),
    };
    setTeamMemberInfo(newObj);
  };

  const constructSubscriptionsData = (subscriptionsData) => {
    let activeSubscriptionList = [];
    if (subscriptionsData?.length > 0) {
      activeSubscriptionList = subscriptionsData.filter(
        (item) => item?.status?.toLowerCase() === "active"
      );
    }
    subscriptionsData &&
      subscriptionsData.map((value, key) => {
        const { trialEnabled = false, trialEndDate = "" } = value;
        const { planName = "", planType = "" } =
          getPlanNameAndType(value) || {};
        setPlanDetails({ planName, planType });
        const newObj = {
          planName:
            activeSubscriptionList?.length > 0
              ? getPlanDetails(value)
              : "Free Plan",
          trialEnabled,
          trialEndDate,
        };
        setSubscriptionInfo(newObj);
      });
  };

  const getPlanNameAndType = (planDetails) => {
    const {
      planName = "",
      lifeTimeDeal = "",
      trialEnabled = "",
      annual = false,
    } = planDetails || {};
    if (!lifeTimeDeal && !trialEnabled) {
      return { planName: planName, planType: annual ? "annual" : "monthly" };
    }
  };

  const getPlanDetails = (planDetails) => {
    const {
      planName = "",
      lifeTimeDeal = false,
      trialEnabled = false,
      annual = false,
    } = planDetails || {};

    if (lifeTimeDeal) {
      return "Dealify starter plan";
    } else if (trialEnabled) {
      return `14 day free trial - ${planName} plan`;
    } else {
      return `${annual ? "Annual" : "Monthly"} ${planName} plan`;
    }
  };

  const getCustomerName = (name, firstName, lastName) => {
    if (name) {
      return name;
    } else if (firstName && lastName) {
      return `${firstName} ${lastName}`;
    } else if (firstName) {
      return firstName;
    } else if (lastName) {
      return lastName;
    }
  };

  const updateAllProps = () => {
    memberData && constructPersonalData(memberData);
    teamData && setTeamInfo(teamData);
    subscriptionsData && constructSubscriptionsData(subscriptionsData);
    teamMemberData && constructTeamMemberData(teamMemberData);
  };

  const handleAdditionalSettings = () => {
    settingsPopupState.setShowPopup(true);
    settingsPopupState.setDefaultPopupvalue({
      cbk: () => {
        settingsPopupState.setShowPopup(false);
      },
      source: "additionalSettings",
    });
  };

  const constructTeamData = (data) => {
    return { ...data };
  };

  const getStripeInfo = async (email, constructedTeamData) => {
    try {
      const config = {
        url: `${URLS.getStripeInfo}`,
        method: "POST",
        data: { email },
      };
      let response = await makeApi(config);
      if (response?.data) {
        setActiveLabelList({
          ...constructedTeamData,
          stripeCustomerId: response?.data?.customerId,
          stripeSubscriptionId: response?.data?.subscriptionId,
        });
      } else {
        Utils.showApiFailureResponse(response);
      }
    } catch (error) {
      console.error("Stripe customer id fetching error ", error);
    }
  };

  const checkForActiveSubscription = () => {
    let activeSubscriptionList = [];
    if (subscriptionsData?.length > 0) {
      activeSubscriptionList = subscriptionsData.filter(
        (item) => item?.status?.toLowerCase() === "active"
      );
    }
    return activeSubscriptionList?.length > 0 || false;
  };

  const handleMemberAction = (type = "") => {
    let email = personalInfo?.email || "";
    if (type && email) {
      let presentType = type?.toLowerCase() === "block" ? "block" : "activate";
      let pastType = type?.toLowerCase() === "block" ? "Blocked" : "Activated";
      let textType =
        type?.toLowerCase() === "block" ? "Blocking" : "Activating";
      let url =
        type?.toLowerCase() === "block"
          ? URLS.blockMember
          : URLS.activateMember;
      if (url) {
        if (Utils.validateEmailInput(email)) {
          confirmationPopupState.setPopupValues({
            content: `Are you sure you want to ${presentType} the user (${email}) ?`,
            actionBtnText: "Yes",
            isCancelBtnNeeded: true,
            loadingMsg: `${textType} member (${email}). Please wait...`,
            callback: async (promptLoading = () => {}) => {
              const config = {
                url: url.replace("<<email>>", email),
              };
              let response = await makeApi(config);
              promptLoading(false);
              confirmationPopupState.setShowPopup(false);
              if (+response?.data === 200) {
                setEmail("");
                toasterState.setToastMsg(
                  `${pastType} user successfully`,
                  "success"
                );
              } else {
                toasterState.setToastMsg(
                  `Failed to ${presentType} user`,
                  "fail"
                );
              }
            },
          });
          confirmationPopupState.setShowPopup(true);
        }
      }
    }
  };

  const buttonList = [
    {
      id: "blockMemberBtn",
      name: "blockMemberBtn",
      type: "button",
      className: `primaryButton`,
      btnText: "Block Member",
      click: () => handleMemberAction("block"),
    },
    {
      id: "activateMemberBtn",
      name: "activateMemberBtn",
      type: "button",
      className: `primaryButton`,
      btnText: "Activate Member",
      click: () => handleMemberAction("activate"),
    },
  ];

  const activeSubscriptionAvailable = checkForActiveSubscription();

  useEffect(() => {
    setPersonalInfo({ ...personalInfo, email: email });
  }, [email]);

  useEffect(() => {
    const constructedTeamData = constructTeamData(teamData);
    setActiveLabelList({ ...constructedTeamData });
    if (!teamData?.inFreePlan && !teamData?.trialEnabled && memberData?.email) {
      getStripeInfo(memberData?.email, constructedTeamData);
    }
  }, [teamData, memberData]);

  useEffect(() => {
    updateAllProps();
  }, [teamData, memberData, subscriptionsData, teamMemberData]);

  useEffect(() => {
    const activeTeamSubscription =
      subscriptionsData.filter(
        (item) => item?.status?.toLowerCase() === "active"
      )?.[0] || {};
    setActiveSubscriptionsData(activeTeamSubscription);
  }, [subscriptionsData]);

  return (
    <div className="tableInfo">
      <div className="innerCont table">
        <div className="titleCont">
          <div className="title">Contact Information</div>
          <div className="buttonWrapper">
            {buttonList.map((item) => (
              <Button {...item} key={item?.id} />
            ))}
            {!activeSubscriptionAvailable ? (
              <div className="flexBetween">
                <CreateTrialUser teamData={teamData} />
                <CreateOrUpdateSubscriptionBtn teamData={teamData} />
              </div>
            ) : (
              <>
                {teamData?.trialEnabled && (
                  <CreateOrUpdateSubscriptionBtn teamData={teamData} />
                )}
                <CreateOrUpdateSubscriptionBtn
                  source="updateSubscription"
                  btnText="Update subscription"
                  subscriptionsData={subscriptionsData}
                />
                <ChangeSubscriptionPlan subscriptionsData={subscriptionsData} />
                <CancelSubscriptionPlan
                  subscriptionsData={subscriptionsData}
                  email={personalInfo?.email}
                />
              </>
            )}
          </div>
        </div>
        <div className="bodyCont">
          <InfoTag
            classValue="name"
            label="Name"
            value={personalInfo?.name?.trim() || teamMemberInfo?.name?.trim()}
          />
          <InfoTag
            classValue="title"
            label="Title"
            value={personalInfo.title}
          />
          <div className={`dataCont totalPrice`}>
            <span className="label">Email</span>
            <span className="separator">:</span>
            {editEmailFlag && (
              <span className="editWrapper">
                {personalInfo?.email}
                <i
                  className="material-icons checkIcon"
                  onClick={() => setEditEmailFlag(false)}
                >
                  edit
                </i>
              </span>
            )}
            {!editEmailFlag && (
              <span>
                <EditEmail
                  email={personalInfo?.email}
                  emailHandler={() => setEditEmailFlag(true)}
                  personalInfoHandler={setEmail}
                />
              </span>
            )}
          </div>
          <InfoTag
            classValue="phone"
            label="Phone"
            value={personalInfo.phone}
          />
          <InfoTag
            classValue="planName"
            label="Plan Name"
            value={
              teamInfo?.teamStatus?.toLowerCase() === "trial_completed"
                ? "trial expired"
                : subscriptionInfo.planName
            }
          />
          <InfoTag
            classValue="source"
            label="Signup source"
            value={personalInfo.source}
          />
          <InfoTag
            classValue="seatsUsed"
            label="Seats used"
            value={teamInfo.noOfSeatsUsed}
          />
          {!teamData?.inFreePlan &&
            !teamData?.trialEnabled &&
            activeSubscriptionsData &&
            Object.keys(activeSubscriptionsData)?.length > 0 && (
              <div className={`dataCont totalPrice`}>
                <div className="label">Total Price</div>
                <span className="separator">:</span>
                <EditPricing
                  email={personalInfo?.email}
                  activeLabelList={activeLabelList}
                  value={activeSubscriptionsData?.price}
                  setActiveSubscriptionsData={setActiveSubscriptionsData}
                />
              </div>
            )}
          {connectedInbox && (
            <InfoTag
              classValue="connectedInbox"
              label="Connected Inbox"
              value={connectedInbox}
            />
          )}
          <div className={`dataCont totalSeats`}>
            <div className="label">Total No of seats</div>
            <span className="separator">:</span>
            <EditSeats
              teamInfo={teamInfo}
              email={personalInfo?.email}
              activeLabelList={activeLabelList}
              value={teamInfo?.noOfSeatsEligible}
              constructTeamData={constructTeamData}
              setActiveLabelList={setActiveLabelList}
            />
          </div>
          {teamData?.trialEnabled && subscriptionInfo?.trialEndDate && (
            <InfoTag
              classValue="trialEndDate"
              label="Trial End date"
              value={
                new Date(subscriptionInfo.trialEndDate).toLocaleDateString() ||
                ""
              }
            />
          )}
          {subscriptionInfo?.trialEndDate && (
            <div className={`dataCont totalSeats`}>
              <div className="label">Extend Trial</div>
              <span className="separator">:</span>
              <ExtendTrial
                teamSubscriptionId={teamSubscriptionId}
                email={personalInfo.email}
              />
            </div>
          )}
          <div
            className="additionalSettings"
            onClick={handleAdditionalSettings}
          >
            <span className="additionalSettingsLabel">
              Update Sales engagement feature flags
            </span>
            <i className="material-icons additionalSettingsIcon">
              arrow_forward
            </i>
          </div>
          <DiscoverSettings
            teamData={teamData}
            email={memberData?.email}
            teamInfo={teamInfo}
            setTeamInfo={setTeamInfo}
          />
          <SettingsPopup>
            {settingsPopupState?.defaultPopupValue?.source ===
              "additionalSettings" && (
              <UpdateUserFeatureFlag
                email={memberData?.email}
                activeLabelList={activeLabelList}
                setActiveLabelList={setActiveLabelList}
                constructTeamData={constructTeamData}
              />
            )}
          </SettingsPopup>
          <SettingsPopup>
            {settingsPopupState?.defaultPopupValue?.source ===
              "createSubscription" && (
              <CreateSubscriptionPlan
                email={memberData?.email}
                activeLabelList={activeLabelList}
                setActiveLabelList={setActiveLabelList}
                constructTeamData={constructTeamData}
                teamData={teamData}
              />
            )}
          </SettingsPopup>
          <SettingsPopup>
            {settingsPopupState?.defaultPopupValue?.source ===
              "updateSubscription" && (
              <UpdateSubscriptionPlan
                email={memberData?.email}
                activeLabelList={activeLabelList}
                setActiveLabelList={setActiveLabelList}
                constructTeamData={constructTeamData}
                teamData={teamData}
                activeSubscriptionsData={activeSubscriptionsData}
                setActiveSubscriptionsData={setActiveSubscriptionsData}
              />
            )}
          </SettingsPopup>
          <SettingsPopup title={"Change Plan"}>
            {settingsPopupState?.defaultPopupValue?.source ===
              "changeSubscription" && (
              <ChangeSubscriptionPopupContent
                email={memberData?.email}
                activeLabelList={activeLabelList}
                setActiveLabelList={setActiveLabelList}
                planDetails={planDetails}
              />
            )}
          </SettingsPopup>
        </div>
      </div>
    </div>
  );
});

export default ContactInfo;
export { ContactInfo };

import React, { useEffect, useState } from "react";

const StandardInputTextBox = (props) => {
  const {
    id,
    name,
    type,
    value,
    readOnly,
    err,
    placeholder = "",
    cbk = () => {},
    inputBoxRef = null,
    focus = false,
    onKeyDown = () => {},
    min = "",
    step = "",
  } = props || {};

  const [updatedValue, setUpdatedValue] = useState("");

  const changeValue = async (event) => {
    cbk(id, event.currentTarget.value);
  };

  useEffect(() => {
    focus && inputBoxRef.current.focus();
  }, []);

  useEffect(() => {
    setUpdatedValue(value);
  }, [value]);

  return (
    <div className={`standardInputWrapper ${id}`}>
      <div className="inputCont">
        <input
          type={type}
          id={id}
          name={name}
          className={`input-box ${err ? "error" : ""}`}
          placeholder={placeholder}
          value={updatedValue}
          onChange={(e) => changeValue(e)}
          readOnly={readOnly}
          ref={inputBoxRef}
          onKeyDown={onKeyDown}
          min={min}
          step={step}
        />
      </div>
      <span
        className="errMsgText"
        dangerouslySetInnerHTML={{
          __html: err || "",
        }}
      />
    </div>
  );
};

export default StandardInputTextBox;

import React, { useState } from "react";
import { customerDetails } from "Stores/customerDetails";
import SettingPopup from "./SettingPopup";
import Utils from "Utils/utils";
import { makeApi, URLS } from "Utils/apiURL";
import { toasterState } from "Components/common/Toaster";

const DiscoverSettings = (props) => {
  const {
    teamData = {},
    email = "",
    teamInfo = {},
    setTeamInfo = () => {},
  } = props || {};

  const [loader, setLoader] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const saveAction = async () => {
    if (email) {
      setLoader(true);
      try {
        let url = `${URLS.updateDataSearchSettings}?email=${email}`;
        const config = {
          url,
          method: "POST",
          data: { ...teamInfo },
        };
        let response = await makeApi(config);
        if (response?.data?.success) {
          let tempObj = {
            ...teamData,
            team: response?.data?.team,
          };
          customerDetails.setCustomerDetails(tempObj);
          setTeamInfo(tempObj);
          toasterState.setToastMsg("Successfully updated", "success");
        } else {
          toasterState.setToastMsg("Failed to update", "fail");
        }
      } catch (err) {
        console.error("ERROR in updating data search settings", err);
      }
      setLoader(false);
    }
  };

  return (
    <div className="discoverSettingSection">
      <div className="actionBtn" onClick={() => setShowPopup(true)}>
        <span className="label">Update data feature flags</span>
        <i className="material-icons">arrow_forward</i>
      </div>
      {showPopup && (
        <SettingPopup
          teamInfo={teamInfo}
          loader={loader}
          setTeamInfo={setTeamInfo}
          close={() => setShowPopup(false)}
          save={saveAction}
        />
      )}
    </div>
  );
};

export default DiscoverSettings;

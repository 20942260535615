import React, { useState, useEffect } from "react";
import { customerDetails } from "Stores/customerDetails";
import Button from "Components/common/Button";
import makeApi, { URLS } from "Utils/apiURL";
import Utils from "Utils/utils";
import { confirmationPopupState } from "Components/common/ConfirmationPopup";
import { toasterState } from "Components/common/Toaster";

const CancelSubscriptionPlan = (props) => {
  const { subscriptionsData = "", email = "" } = props;

  const { id: teamSubscriptionId = "" } =
    (subscriptionsData?.length && subscriptionsData[0]) || "";
  const [subscriptionData, setSubscriptionData] = useState(subscriptionsData);

  const toggleLoaderFlag = (subid, flag) => {
    if (subid && subscriptionData) {
      subscriptionData.map((value) => {
        if (value && value.id && value.id === subid) {
          value.planStatusLoader = flag;
        } else if (subid === null) {
          value.planStatusLoader = flag;
        }
      });
      setSubscriptionData(Utils.removeReferenceFromObject(subscriptionData));
    }
  };

  const cancelSubscriptionCbk = async (buttonLoadingFn) => {
    try {
      const config = {
        url: URLS.cancelSubscription,
        method: "POST",
        data: { email },
      };
      toggleLoaderFlag(teamSubscriptionId, true);
      let response = await makeApi(config);
      if (response?.data) {
        const respData = response.data;
        buttonLoadingFn(false);
        confirmationPopupState.setShowPopup(false);
        if (respData.success) {
          customerDetails.getCustomerDetails(customerDetails.customerEmail);
          toasterState.setToastMsg(
            "Subscription cancelled successfully",
            "success"
          );
        } else {
          toggleLoaderFlag(teamSubscriptionId, false);
          toasterState.setToastMsg("Something went wrong", "failure");
        }
      } else {
        Utils.showApiFailureResponse(response);
        toggleLoaderFlag(teamSubscriptionId, false);
      }
    } catch (error) {
      console.error("Cancel Subscription error ", error);
    }
  };

  const handleCancelSubscription = () => {
    confirmationPopupState.setPopupValues({
      content: "Do you want to cancel the subscription for this user?",
      actionBtnText: "Yes",
      cancelBtnText: "No",
      isCancelBtnNeeded: true,
      callback: cancelSubscriptionCbk,
      loadingMsg: "Cancelling subscription. Please wait...",
    });
    confirmationPopupState.setShowPopup(true);
  };

  return (
    <Button
      id="cancelSubscription"
      name="cancelSubscription"
      type="button"
      className="primaryButton cancelSubscriptionButton"
      btnText="Cancel Subscription"
      icon={false}
      loader={false}
      click={handleCancelSubscription}
    />
  );
};

export { CancelSubscriptionPlan };
export default CancelSubscriptionPlan;

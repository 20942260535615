import React from "react";

const Button = props => {
  const {
    id,
    name,
    btnText,
    className,
    click,
    type,
    loader,
    icon,
    iconName
  } = props;
  return (
    <button
      type={type}
      id={id}
      name={name}
      className={`animatedButton ${className}`}
      onClick={click}
    >
      {loader && <span className="load-text"></span>}
      {!loader && btnText}
      {icon && <i className="material-icons-outlined">{iconName}</i>}
    </button>
  );
};

export default Button;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import StandardInputTextBox from "Components/common/StandardInputTextBox";
import Button from "Components/common/Button";
import { customerDetails } from "Stores/customerDetails";
import { withRouter } from "react-router-dom";

const DateFilter = (props) => {
  const defaultInputState = { fromDate: "", toDate: "" };
  const {
    showDateFilter,
    title = "",
    className = "",
  } = props;
  const [dateObj, setDateObj] = useState(defaultInputState);
  const [fromDateErrorMsg, setFromDateErrorMsg] = useState("");
  const [toDateErrorMsg, setToDateErrorMsg] = useState("");
  const [buttonLoader, setButtonLoader] = useState(false);

  const onDateChange = (id, value) => {
    if (id && value) {
      id === "fromDate" && setFromDateErrorMsg("");
      id === "toDate" && setToDateErrorMsg("");
      const stateObj = dateObj;
      stateObj[id] = value && new Date(value).toISOString();
      setDateObj(stateObj);
    }
  };

  const redirectionCbk = () => {
    showDateFilter(true);
    setButtonLoader(false);
  };

  const errorCbk = () => {
    setButtonLoader(false);
  };

  const buttonCbk = () => {
    const { fromDate, toDate } = dateObj;
    if (fromDate && toDate) {
      setButtonLoader(true);
      if (fromDate === toDate) {
        dateObj.toDate = toDate?.replace("T00:00:00.000", "T23:59:00.000");
      }
      customerDetails.getNewTrialUserList(dateObj, redirectionCbk, errorCbk);
      setFromDateErrorMsg("");
      setToDateErrorMsg("");
    } else if (fromDate === "" && toDate === "") {
      setFromDateErrorMsg("Please select from date");
      setToDateErrorMsg("Please select to date");
    } else if (fromDate === "") {
      setFromDateErrorMsg("Please select from date");
      setToDateErrorMsg("");
    } else if (toDate === "") {
      setToDateErrorMsg("Please select to date");
      setFromDateErrorMsg("");
    }
  };

  return (
    <form className={`${className}`} onSubmit={(e) => buttonCbk(e)}>
      {title && (
        <div className="customerHeader">
          <div className="titleCont">
            <span className="title">{title}</span>
          </div>
        </div>
      )}
      <div className="filterWrapper">
        <div className="fromDateContainer">
          <span className="dateHeader">From</span>
          <StandardInputTextBox
            name="fromDate"
            id="fromDate"
            placeholder="Enter from date"
            cbk={onDateChange}
            err={fromDateErrorMsg}
            type="date"
            readOnly={false}
          />
        </div>
        <div className="toDateContainer">
          <span className="dateHeader">To</span>
          <StandardInputTextBox
            name="toDate"
            id="toDate"
            placeholder="Enter to date"
            err={toDateErrorMsg}
            cbk={onDateChange}
            type="date"
            readOnly={false}
          />
        </div>
      </div>
      <div className="buttonCont">
        <Button
          id="applyFilter"
          name="applyFilter"
          type="button"
          className={`primaryButton ${buttonLoader ? "loading" : ""}`}
          btnText="Submit"
          icon={false}
          loader={buttonLoader}
          click={buttonCbk}
        />
      </div>
    </form>
  );
};

export default withRouter(DateFilter);
export { DateFilter };

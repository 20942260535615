import React, { useState } from "react";
import Utils from "Utils/utils";
import { makeApi, URLS } from "Utils/apiURL";
import EditPropertyValue from "./EditPropertyValue";

const EditSeats = (props) => {
  const {
    email = "",
    value = "",
    teamInfo = {},
    constructTeamData = () => {},
    setActiveLabelList = () => {},
  } = props || {};

  const [loading, setLoading] = useState(false);
  const [inputBoxError, setInputBoxError] = useState("");
  const [editInputBox, setEditInputBox] = useState(false);
  const [previousSeatsValue, setPreviousSeatsValue] = useState("");
  const [noOfSeats, setNoOfSeats] = useState(teamInfo?.noOfSeatsEligible);

  const updateSeatsCount = async () => {
    if (noOfSeats) {
      setLoading(true);
      const config = {
        url: URLS.addMoreSeats,
        method: "POST",
        data: { seats: noOfSeats, email: email },
      };
      let response = await makeApi(config);
      if (response?.data) {
        const { team = {} } = response?.data || {};
        const { noOfSeatsEligible = 0 } = team || {};
        setLoading(false);
        setNoOfSeats(noOfSeatsEligible);
        setPreviousSeatsValue(noOfSeatsEligible);
        const constructedTeamData = constructTeamData(team);
        setActiveLabelList({ ...constructedTeamData });
        setEditInputBox(false);
        setInputBoxError("");
      } else {
        Utils.showApiFailureResponse(response);
      }
    } else {
      setTimeout(() => {
        setInputBoxError("");
      }, 2000);
      setInputBoxError("Please provide valid number of seats");
    }
  };

  return (
    <EditPropertyValue
      value={value}
      id="editSeats"
      name="editSeats"
      loading={loading}
      updatedValue={noOfSeats}
      editInputBox={editInputBox}
      inputBoxError={inputBoxError}
      updateFunc={updateSeatsCount}
      setUpdatedValue={setNoOfSeats}
      setEditInputBox={setEditInputBox}
      previousValue={previousSeatsValue}
      setPreviousValue={setPreviousSeatsValue}
    />
  );
};

export { EditSeats };
export default EditSeats;

import React from "react";
import { observable, action } from "mobx";
import { observer } from "mobx-react";

class SettingsPopupState {
  defaultPopupObj = {
    cbk: () => {},
    children: <></>,
    source: "",
  };
  @observable showPopup = false;
  @observable defaultPopupValue = this.defaultPopupObj;

  @action
  setShowPopup = (value) => {
    this.showPopup = value;
  };

  @action
  setDefaultPopupvalue = (value) => {
    this.defaultPopupValue = value;
  };
}

const settingsPopupState = new SettingsPopupState();

const SettingsPopup = observer((props) => {
  const { children = <></>, title = "Update Sales engagement feature flags" } = props;
  return (
    settingsPopupState.showPopup && children && (
      <div className="settingsPopupWrapper">
        <div
          className={`settingsPopupContainer ${settingsPopupState?.defaultPopupValue?.source}PopupWrapper`}
        >
          <div className="settingsPopupHeader">
            <span>{title}</span>
            <i
              className="material-icons closeIcon"
              onClick={settingsPopupState?.defaultPopupValue?.cbk}
            >
              close
            </i>
          </div>
          <div className="settingsPopupBody">{children}</div>
        </div>
        {settingsPopupState.showPopup && (
          <div
            className="settingsPopupOverlay"
            onClick={settingsPopupState?.defaultPopupValue?.cbk}
          />
        )}
      </div>
    )
  );
});

export { SettingsPopup, settingsPopupState };
export default SettingsPopup;

/* eslint-disable import/no-unresolved */
import React, { useEffect } from "react";
import { ReactComponent as ForbiddenIcon } from "Assets/svg/403.svg";

/* eslint-disable import/no-unresolved */

const MemberUnAuthorized = props => {
  const init = () => {
    var root = document.documentElement;
    var eyef = document.getElementById("eyef");
    var cx = document.getElementById("eyef").getAttribute("cx");
    var cy = document.getElementById("eyef").getAttribute("cy");

    document.addEventListener("mousemove", evt => {
      let x = evt.clientX / window.innerWidth;
      let y = evt.clientY / window.innerHeight;

      root.style.setProperty("--mouse-x", x);
      root.style.setProperty("--mouse-y", y);

      cx = 115 + 30 * x;
      cy = 50 + 30 * y;
      eyef.setAttribute("cx", cx);
      eyef.setAttribute("cy", cy);
    });

    document.addEventListener("touchmove", touchHandler => {
      let x = touchHandler.touches[0].clientX / window.innerWidth;
      let y = touchHandler.touches[0].clientY / window.innerHeight;
      root.style.setProperty("--mouse-x", x);
      root.style.setProperty("--mouse-y", y);
    });
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <div className="forbiddenPage">
      <div className="layout">
        <div className="content">
          <ForbiddenIcon />
          <span className="error-message">
            You don't have permission to access this site. Please contact
            administrator
          </span>
        </div>
      </div>
    </div>
  );
};

export { MemberUnAuthorized };
export default MemberUnAuthorized;

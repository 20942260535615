/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { withRouter } from "react-router-dom";
import DashboardRouting from "Routes/DashboardRouting";
import SideNav from "Components/side-nav/sideNav";
import { observer } from "mobx-react";
import { ConfirmationPopup } from "Components/common/ConfirmationPopup";
import { Toaster } from "Components/common/Toaster";

const DashboardModule = props => {
  return (
    <>
      <aside>
        <SideNav />
      </aside>
      <ConfirmationPopup />
      <Toaster />
      <section className="site-content">
        <main>
          <DashboardRouting />
        </main>
      </section>
    </>
  );
};

export default withRouter(observer(DashboardModule));

/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { observer } from "mobx-react";
import { Route, Switch, Redirect } from "react-router-dom";
import ManageCustomers from "Pages/Dashboard/ManageCustomers/main";

const DashboardRouting = () => {
  return (
    <Switch>
      <Route path="/admin/manageCustomers" component={ManageCustomers} />
      <Redirect from="*" to="/admin/manageCustomers" />
    </Switch>
  );
};

export default observer(DashboardRouting);

import React from "react";
import ToogleButton from "Components/common/ToogleButton";
import InputTextBox from "Components/common/InputTextBox";
import Button from "Components/common/Button";

const SettingPopup = (props) => {
  const {
    teamInfo = {},
    setTeamInfo = {},
    close = () => {},
    loader = () => {},
    save = () => {},
  } = props || {};

  const {
    creditsDailyLimitEnabled = false,
    emailCreditsDailyLimit = 0,
    emailCreditsMonthlyLimit = 0,
    phoneCreditsDailyLimit = 0,
    phoneCreditsMonthlyLimit = 0,
    phoneCreditsAnnualLimit = 0,
    paginationLimit = 0,
    searchEnabled = false,
    phoneEnabled = false,
    bulkPurchaseEnabled = false,
    bulkPurchaseLimit = 0,
  } = teamInfo || {};

  const handleAction = (id = "", value) => {
    id && setTeamInfo({ ...teamInfo, [id]: value });
  };

  const emailCreditInputObjectList = [
    {
      name: "emailCreditsDailyLimit",
      id: "emailCreditsDailyLimit",
      text: "Daily Limit",
      type: "number",
      value: emailCreditsDailyLimit,
      change: handleAction,
      showDom: creditsDailyLimitEnabled,
    },
    {
      name: "emailCreditsMonthlyLimit",
      id: "emailCreditsMonthlyLimit",
      text: "Monthly Limit",
      type: "number",
      value: emailCreditsMonthlyLimit,
      change: handleAction,
    },
  ];

  const phoneCreditInputObjectList = [
    {
      name: "phoneCreditsDailyLimit",
      id: "phoneCreditsDailyLimit",
      text: "Daily Limit",
      type: "number",
      value: phoneCreditsDailyLimit,
      change: handleAction,
    },
    {
      name: "phoneCreditsMonthlyLimit",
      id: "phoneCreditsMonthlyLimit",
      text: "Monthly Limit",
      type: "number",
      value: phoneCreditsMonthlyLimit,
      change: handleAction,
    },
    {
      name: "phoneCreditsAnnualLimit",
      id: "phoneCreditsAnnualLimit",
      text: "Annual Limit",
      type: "number",
      value: phoneCreditsAnnualLimit,
      change: handleAction,
    },
  ];

  const searchPaginationLimit = {
    name: "paginationLimit",
    id: "paginationLimit",
    text: "Pagination Limit",
    type: "number",
    value: paginationLimit,
    change: handleAction,
  };

  const saveButton = {
    id: "saveChangesCreditButton",
    name: "saveChangesCreditButton",
    type: "button",
    className: `primaryButton ${loader ? "loading" : ""}`,
    btnText: "Save Changes",
    loader,
    click: save,
  };

  const bulkPurchaseLimitInputObject = {
    name: "bulkPurchaseLimit",
    id: "bulkPurchaseLimit",
    text: "Bulk Purchase Limit",
    type: "number",
    value: bulkPurchaseLimit,
    change: handleAction,
    showDom: bulkPurchaseEnabled,
  };

  return (
    <div className="popup">
      <div className="popupBody">
        <i className="material-icons close" onClick={close}>
          close
        </i>
        <div className="popupCard">
          <h3>Update data feature flags</h3>
          <div className="emailCreditLimitSection">
            <h4>Email credit limit</h4>
            <ToogleButton
              id="creditsDailyLimitEnabled"
              label="Enable daily limit"
              checked={creditsDailyLimitEnabled}
              callback={handleAction}
            />
            <div className="inputContainer">
              {emailCreditInputObjectList.map((item, index) => (
                <InputTextBox {...item} key={`emailCreditsInputBox${index}`} />
              ))}
            </div>
          </div>
          <div className="phoneCreditLimitSection">
            <h4>Phone number credit limit</h4>
            <ToogleButton
              id="phoneEnabled"
              label="Allow phone number purchase"
              checked={phoneEnabled}
              callback={handleAction}
            />
            {phoneEnabled && (
              <div className="inputContainer">
                {phoneCreditInputObjectList.map((item, index) => (
                  <InputTextBox
                    {...item}
                    key={`phoneCreditsInputBox${index}`}
                  />
                ))}
              </div>
            )}
          </div>
          <div className="twoColLayout">
            <ToogleButton
              id="searchEnabled"
              label="Search section Enabled"
              checked={searchEnabled}
              callback={handleAction}
            />
            <div className="inputContainer">
              <InputTextBox {...searchPaginationLimit} />
            </div>
          </div>
          <div className="bulkPurchaseSection">
            <h4>Bulk Purchase Feature Flag</h4>
            <div className="twoColLayout">
              <ToogleButton
                id="bulkPurchaseEnabled"
                label="Enable/Disable Bulk Purchase"
                checked={bulkPurchaseEnabled}
                callback={handleAction}
              />
              <div className="inputContainer">
                <InputTextBox {...bulkPurchaseLimitInputObject} />
              </div>
            </div>
          </div>
        </div>
        <div className="popupFooter">
          <Button {...saveButton} />
        </div>
      </div>
      <div className="bgOverlay" onClick={() => (!loader && close()) || {}} />
    </div>
  );
};

export default SettingPopup;

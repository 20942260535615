/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import InfoTag from "Components/common/InfoTag";
import userDetail from "Stores/userDetail";
import { confirmationPopupState } from "Components/common/ConfirmationPopup";
import { customerDetails } from "Stores/customerDetails";
import Button from "Components/common/Button";
import Utils from "Utils/utils";

const WipeAllData = props => {
  const { data, jobStatus, cbk } = props;
  const [teamMember, setTeamMember] = useState([]);

  const requestWipeAllData = () => {
    const wipeAllDataRequest = () => {
      const closeLoader = () => {
        confirmationPopupState.setShowPopup(false);
        cbk({
          job: { status: "TO_BE_PROCESSED" }
        });
      };
      confirmationPopupState.setShowPopup(true);
      customerDetails.wipeAllDataRequest(
        customerDetails.customerEmail,
        closeLoader
      );
    };

    confirmationPopupState.setPopupValues({
      content:
        "Are you sure you want to wipe all data of this account? This will remove all the data associated with this account and the team members of this account.",
      actionBtnText: "Yes",
      isCancelBtnNeeded: true,
      callback: wipeAllDataRequest,
      loadingMsg: "Please wait..."
    });
    confirmationPopupState.setShowPopup(true);
  };

  const getFullName = (fn, ln) => {
    if (fn && fn !== "" && ln && ln !== "") {
      return `${fn} ${ln}`;
    }
    return "";
  };

  const showWipeAllData = () => {
    if (
      jobStatus &&
      Object.keys(jobStatus).length > 0 &&
      (jobStatus.job === null ||
        (jobStatus.job.status && jobStatus.job.status === "PROCESSED"))
    ) {
      return true;
    }
    return false;
  };

  const showJobStatusText = () => {
    if (
      jobStatus &&
      Object.keys(jobStatus).length > 0 &&
      jobStatus.job &&
      jobStatus.job.status &&
      (jobStatus.job.status === "TO_BE_PROCESSED" ||
        jobStatus.job === "IN_PROGRESS")
    ) {
      return true;
    }
    return false;
  };

  const checkUserIsSuperAdmin = () => {
    return (
      userDetail.userInfo &&
      userDetail.userInfo.role &&
      userDetail.userInfo.role === 200
    );
  };
  useEffect(() => {
    if (data && data.length > 0) {
      setTeamMember(data);
    }
  }, [data]);

  return teamMember && teamMember.length > 0 ? (
    <div className="tableInfo wipeAllDataCard">
      <div className="innerCont table">
        <div className="titleCont">
          <div className="title">Team members</div>
        </div>
        <div className="bodyCont">
          {teamMember.map((value, key) => {
            const {
              firstName,
              lastName,
              email,
              jobTitle,
              role,
              status,
              createTs
            } = value;
            return (
              <div key={key} className="wipeAllDataItemList">
                <InfoTag
                  classValue="memberName"
                  label="Name"
                  value={getFullName(firstName, lastName)}
                />
                <InfoTag classValue="email" label="Email" value={email} />
                <InfoTag
                  classValue="memberJobTitle"
                  label="Job Title"
                  value={jobTitle}
                />
                <InfoTag classValue="memberRole" label="Role" value={role} />
                <InfoTag
                  classValue="memberStatus"
                  label="Member Status"
                  value={status}
                />
                <InfoTag
                  classValue="memberSignupDate"
                  label="Signup Date"
                  value={Utils.formatDate(createTs)}
                />
              </div>
            );
          })}
        </div>
        {checkUserIsSuperAdmin() && (
          <div className="btnSection">
            {showWipeAllData() && (
              <Button
                id="userDetails"
                name="userDetails"
                type="button"
                className="primaryButton"
                btnText="Wipe All Data"
                icon={false}
                loader={false}
                click={requestWipeAllData}
                iconName=""
              />
            )}
            {showJobStatusText() && (
              <div className="jobStatus">
                Wipe all data{" "}
                {jobStatus.job.status === "IN_PROGRESS"
                  ? "in progress"
                  : "to be processed"}
                …{" "}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  ) : (
    ""
  );
};

export default WipeAllData;
export { WipeAllData };

import React from "react";

const JumpBallLoader = props => {
  let { color } = props;
  return (
    <div className="ball-container">
      <div
        className="ball ball-one"
        style={{ background: color ? color : "#3953fb" }}
      ></div>
      <div
        className="ball ball-two"
        style={{ background: color ? color : "#3953fb" }}
      ></div>
      <div
        className="ball ball-three"
        style={{ background: color ? color : "#3953fb" }}
      ></div>
      <div
        className="ball ball-four"
        style={{ background: color ? color : "#3953fb" }}
      ></div>
      <div className="shadow shadow-one"></div>
      <div className="shadow shadow-two"></div>
      <div className="shadow shadow-three"></div>
      <div className="shadow shadow-four"></div>
    </div>
  );
};

export default JumpBallLoader;

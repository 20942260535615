import React, { useRef, useEffect, useState } from "react";
import { makeApi, URLS } from "Utils/apiURL";


const EditEmail = (props) => {
  const oldemail = props.email;
  const [emailvalue, setEmailValue] = useState(oldemail || "");
  const [loader, setLoader] = useState(false);
  const [errorFlag, setErrorFlag] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");


  const emailUpdateFunc = async () => {
    setLoader(true);
    const config = {
      url: `${URLS.updateEmail}${oldemail}/${emailvalue}`,
      method: "POST",
    };
    let response = await makeApi(config);
    if (response?.status === 200) {
      if (response?.data === true) {
        props.personalInfoHandler(emailvalue);
        props.emailHandler();
      }
      else {
        setErrorFlag(true);
        setErrorMessage("Invalid email id");
      }
    }
    else if (response?.response?.status === 400) {
      setErrorFlag(true);
      setErrorMessage("Email id already exists");
    }
    else {
      setErrorFlag(true);
      setErrorMessage("Invalid email id");
    }
    setLoader(false);
  }

  const onEmailValueChange = (e) => {
    setErrorFlag(false);
    setEmailValue(e.target.value);
  };



  return (
    <div>
      <div className="editWrapper editEmailInput">
        <input
          value={emailvalue}
          onChange={onEmailValueChange} />

        {loader && <span className="loader"></span>}
        {!loader && <i
          className="material-icons checkIcon"
          onClick={() => emailUpdateFunc()}>
          check
        </i>}
        <i
          className="material-icons closeIcon"
          onClick={props.emailHandler}
        >
          close
        </i>

      </div>
      {errorFlag && <div className="errMessageText">{errorMessage}</div>}
    </div>
  )

}

export default EditEmail
import React, { useEffect, useState } from "react";

export const InputTextBox = (props) => {
  const {
    id = "",
    name = "",
    type = "",
    value = "",
    text = "",
    err = "",
    dynamicText = "",
    className = "",
    showDom = true,
    readOnly = false,
    autoFocus = false,
    mandatory = false,
    placeHolder = "",
    required = false,
    inputBoxRef = null,
    allowFloatNumber = false,
    minValue = 0,
    parentIndex = "",
    childIndex = "",
    cbk = () => {},
    change = () => {},
    onFocus = () => {},
    onKeyDown = () => {},
  } = props || {};

  const [updatedValue, setUpdatedValue] = useState(value);

  const getNumber = (no = 0) => {
    return allowFloatNumber ? no : parseInt(no);
  };
  const changeValue = (event) => {
    let val = event.currentTarget.value;
    val = type?.toLowerCase() === "number" ? getNumber(val) : val;
    change(id, val, parentIndex, childIndex);
  };

  useEffect(() => {
    setUpdatedValue(value);
  }, [value]);

  return (
    showDom && (
      <div className={`inputBoxWrapper ${id || ""}`}>
        <div className={`inputCont ${(readOnly && "readOnlyInput") || ""}`}>
          <input
            id={id}
            placeholder={placeHolder}
            autoFocus={autoFocus}
            autoComplete="off"
            name={name}
            className={`inputBox ${className} ${
              value?.length || value >= 0 ? "filled" : ""
            }`}
            type={type}
            value={updatedValue}
            readOnly={readOnly}
            min={minValue}
            required={typeof required !== undefined ? required : true}
            ref={inputBoxRef}
            onChange={(e) => changeValue(e)}
            onKeyUp={(e) => cbk(e)}
            onFocus={(e) => onFocus(e)}
            onKeyDown={(e) => onKeyDown(e)}
          />
          {!readOnly && <span className="focusBorder"></span>}
          {text && (
            <label htmlFor={id} className={`inputLabel`}>
              {text}
              {mandatory && <span className="required">*</span>}
            </label>
          )}
        </div>
        <span
          className="errMsgText"
          dangerouslySetInnerHTML={{
            __html: err || "",
          }}
        />
        {dynamicText && (
          <div
            className="dynamicText"
            dangerouslySetInnerHTML={{
              __html: dynamicText || "",
            }}
          />
        )}
      </div>
    )
  );
};

export default InputTextBox;
